import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function SearchFilter({ searchData, setSearchData, filterData }) {
  const [isContentDisabled, setContentDisabled] = useState(false);
  const [isProviderDisabled, setProviderDisabled] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const contentTypeList = [
    { label: 'live', value: 'live', type: 'channel' },
    { label: 'tvshow', value: 'tvshow', type: 'vod' },
    { label: 'movie', value: 'movie', type: 'vod' },
    { label: 'tvshow-series', value: 'tvshow-series', type: 'vod' },
    { label: 'livestream', value: 'livestream', type: 'vod' },
    { label: 'tab', value: 'tab', type: 'hub' },
    // hidden features
    // { label: 'category', value: 'category', type: 'hub' },
  ];
  useEffect(() => {
    if (searchData.countryCode !== '') {
      if (searchData.carouselType === 'channel') {
        setProviderList(filterData.countryList.find(f => f.countryCode === searchData.countryCode).channelProviderList.map(m => m.channelProviderId));
      } else if (searchData.carouselType === 'vod') {
        setProviderList(filterData.countryList.find(f => f.countryCode === searchData.countryCode).vodProviderList.map(m => m.vodProviderId));
      } else {
        setProviderList([]);
      }
    }
  }, [searchData.carouselType]);

  const handleSearchFilter = e => {
    const { name, value } = e.target;
    if (name === 'countryCode') {
      if (filterData.carouselTypeList[0] === 'channel' || filterData.carouselTypeList[0] === 'vod') {
        setContentDisabled(false);
        setProviderDisabled(false);
      } else {
        setContentDisabled(true);
        setProviderDisabled(true);
      }
      setSearchData({ ...searchData, ...{ [name]: value, carouselType: filterData.carouselTypeList[0], contentType: 'live', provider: '' } });
    } else if (name === 'carouselType') {
      let contentType = 'live';
      if (value === 'channel') {
        setContentDisabled(false);
        setProviderDisabled(false);
        contentType = 'live';
      } else if (value === 'vod') {
        contentType = 'tvshow';
        setContentDisabled(false);
        setProviderDisabled(false);
      } else if (value === 'hub') {
        contentType = 'tab';
        setContentDisabled(false);
        setProviderDisabled(true);
      } else {
        contentType = '';
        setContentDisabled(true);
        setProviderDisabled(true);
      }
      setSearchData({ ...searchData, ...{ countryCode: searchData.countryCode, [name]: value, contentType: contentType, provider: '' } });
    } else if (name === 'contentType') {
      setSearchData({ ...searchData, ...{ countryCode: searchData.countryCode, carouselType: searchData.carouselType, [name]: value, provider: '' } });
    } else {
      setSearchData({ ...searchData, ...{ countryCode: searchData.countryCode, carouselType: searchData.carouselType, contentType: searchData.contentType, [name]: value } });
    }
  };

  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select name="countryCode" id="countryCode" value={searchData.countryCode} onChange={e => handleSearchFilter(e)}>
            {filterData.countryList.map(n => (
              <MenuItem key={n.countryCode} value={n.countryCode}>
                {n.countryCode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Carousel Type</InputLabel>
          <Select name="carouselType" id="carouselType" value={searchData.carouselType} onChange={e => handleSearchFilter(e)}>
            {filterData.carouselTypeList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>content Type</InputLabel>
          <Select name="contentType" id="contentType" value={searchData.contentType} onChange={e => handleSearchFilter(e)} disabled={isContentDisabled}>
            {contentTypeList
              .filter(f => f.type === searchData.carouselType)
              .map(n => (
                <MenuItem key={n.label} value={n.label}>
                  {n.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>provider</InputLabel>
          <Select name="provider" id="provider" value={searchData.provider ? searchData.provider : 'ALL'} onChange={e => handleSearchFilter(e)} disabled={isProviderDisabled}>
            {['ALL', ...providerList].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
}
SearchFilter.propTypes = {
  filterData: PropTypes.object.isRequired,
  searchData: PropTypes.object.isRequired,
  setSearchData: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  homeAppResult: state.homeApp.manualResultList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchFilter));
