import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { Button, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableCell, TableContainer, TableRow, TextField, Checkbox } from '@material-ui/core';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import AlertDialog from '../../../common/dialog/AlertDialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PolicyContent from '../PolicyContent';

function TermsPolicyForm({ title, edit, detail, closeHandler, getTermsPolicyList, supportLanguages, ricCountry, regionCountryByDevice }) {
  const compareVersions = require('compare-versions');
  const defaultTermsPolicy = {
    idx: -1,
    policyId: '',
    policyType: '',
    policyVersion: '0.0.0',
    definedVersion: '0.0.0',
    policyMajorVersion: 0,
    policyMinorVersion: 0,
    policyPatchVersion: 0,
    deviceType: '',
    updateVersion: '',
    devicePlatform: '',
    policyComment: '',
    displayOption: '',
    displayOrder: 1,
    enableFlag: '',
    publishedFlag: '',
    releaseFlag: 'N',
    publishedVersion: '',
    countries: '',
    crtUsrId: '',
    crtDate: '',
    contentLanguageCodeList: [],
    countryCodeList: [],
    devicePlatformCodeList: [],
    contentList: [],
    countryList: [],
    devicePlatformList: [],
  };
  const defaultContent = {
    contentIdx: null,
    languageCode: '',
    policyTitle: '',
    policyDescription: '',
    contentCrtUsrId: '',
    contentCrtDate: null,
  };
  const devicePlatform = [
    { type: 'tv', value: 'webOS' },
    { type: 'auto', value: 'webOS' },
    { type: 'mobile', value: 'Android' },
    { type: 'mobile', value: 'IOS' },
    { type: 'pc', value: 'pc_platform' },
  ];
  const displayOption = ['mandatory', 'optional', 'notice'];
  const [saveMethod, setSaveMethod] = useState('POST');
  const [confirmDescription, setConfirmDescription] = useState('Would you like to register?');
  const [validDescription, setValidDescription] = useState('Would you like to register?');
  const [isSave, setIsSave] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [termsPolicy, setTermsPolicy] = useState(defaultTermsPolicy);
  const [savedCountryList, setSavedCountryList] = useState([]);
  const [savedPlatformList, setSavedPlatformList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [countryDropDownList, setCountryDropDownList] = useState([]);
  const [languageDropDownList, setLanguageDropDownList] = useState([]);

  useEffect(() => {
    if (edit && detail) {
      setTermsPolicy(detail);
      setSavedCountryList(detail.countryList);
      setSavedPlatformList(detail.devicePlatformList);
    } else {
      setTermsPolicy(detail);
      setSavedCountryList([]);
      setSavedPlatformList([]);
    }
  }, [detail]);

  useEffect(() => {
    if (regionCountryByDevice && regionCountryByDevice.length > 0) {
      setDeviceTypeList(regionCountryByDevice.map(device => device.deviceType));
    }
  }, [regionCountryByDevice]);

  useEffect(() => {
    if (termsPolicy.deviceType && regionCountryByDevice && supportLanguages && regionCountryByDevice.length > 0 && supportLanguages.languageByCountry.length > 0) {
      let country = regionCountryByDevice
        .find(device => termsPolicy.deviceType === device.deviceType)
        .regionList.map(country => country.countryList)
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
        .map(m => m.countryCode);
      country = [...new Set(country)];
      let deviceCountry = supportLanguages.languageByCountry.filter(f => country.includes(f.countryCode));
      setCountryDropDownList(deviceCountry);
    }
  }, [termsPolicy.deviceType]);

  useEffect(() => {
    if (supportLanguages && supportLanguages.distinctLanguage.length > 0) {
      setLanguageDropDownList(supportLanguages.distinctLanguage.map(m => m.languageCode));
    }
  }, [supportLanguages]);

  const termsPolicyInputChangeHandler = e => {
    const { name, value } = e.target;
    if (name === 'deviceType') {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value, devicePlatformCodeList: [], countryCodeList: [], countryList: [] } });
    } else if (name === 'policyComment') {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value } });
    } else if (name === 'displayOrder') {
      if (isEmpty(value)) {
        setTermsPolicy({ ...termsPolicy, ...{ [name]: '' } });
      } else {
        if (isFinite(value)) {
          setTermsPolicy({ ...termsPolicy, ...{ [name]: Number(value) < 1 ? 1 : value } });
        }
      }
    } else if (name === 'contentLanguageCodeList') {
      const termsPolicyContentList = [...termsPolicy.contentList];
      if (value.length === 0) {
        setTermsPolicy({ ...termsPolicy, ...{ [name]: value, contentList: [] } });
      } else if (termsPolicy.contentList.length > value.length) {
        let filterContentList = termsPolicyContentList.filter(f => value.includes(f.languageCode));
        setTermsPolicy({ ...termsPolicy, ...{ [name]: value, contentList: filterContentList } });
      } else if (termsPolicy.contentList.length < value.length) {
        let newContentCountry = value.filter(f => ![...termsPolicy.contentList].map(m => m.languageCode).includes(f));
        let addContent = { ...defaultContent };
        addContent.languageCode = newContentCountry.toString();
        termsPolicyContentList.push(addContent);
        setTermsPolicy({ ...termsPolicy, ...{ [name]: value, contentList: termsPolicyContentList } });
      }
    } else if (name === 'countryCodeList') {
      let list = ricCountry
        .filter(f => value.includes(f.country_code))
        .map(m => ({
          countryCode: m.country_code,
          ricCode: m.ric_code,
          countryCrtUsrId: savedCountryList.find(f => f.countryCode === m.country_code) ? savedCountryList.find(f => f.countryCode === m.country_code).countryCrtUsrId : '',
          countryCrtDate: savedCountryList.find(f => f.countryCode === m.country_code) ? savedCountryList.find(f => f.countryCode === m.country_code).countryCrtDate : null,
        }));
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value, countryList: list } });
    } else if (name === 'devicePlatformCodeList') {
      let list = [];
      if (value && value.length > 0) {
        list = value.map(m => ({
          devicePlatform: m,
          platformCrtUsrId: savedPlatformList.find(f => f.devicePlatform === m) ? savedPlatformList.find(f => f.devicePlatform === m).platformCrtUsrId : '',
          platformCrtDate: savedPlatformList.find(f => f.devicePlatform === m) ? savedPlatformList.find(f => f.devicePlatform === m).platformCrtDate : null,
        }));
      }
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value, devicePlatformList: list } });
    } else {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value } });
    }
  };

  const contentChangeHandler = contentData => {
    setTermsPolicy({ ...termsPolicy, ...{ contentList: contentData } });
  };

  const confirmHandler = e => {
    e.preventDefault();
    // Policy Type
    if (isEmpty(termsPolicy.policyType)) {
      setValidDescription('No policy type has been selected.');
      setIsAlert(true);
      return;
    }
    // Device Type
    if (isEmpty(termsPolicy.deviceType)) {
      setValidDescription('No device type has been selected.');
      setIsAlert(true);
      return;
    }
    // Device Platform
    if (isEmpty(termsPolicy.devicePlatformCodeList)) {
      setValidDescription('No device Platform has been selected.');
      setIsAlert(true);
      return;
    }
    // Policy Comment
    if (isEmpty(termsPolicy.policyComment)) {
      setValidDescription('Please enter your policy comments.');
      setIsAlert(true);
      return;
    }
    // version
    if (isEmpty(termsPolicy.policyVersion) || !compareVersions.validate(termsPolicy.policyVersion)) {
      setValidDescription('The version format to apply was entered incorrectly.');
      setIsAlert(true);
      return;
    }
    if (!compareVersions.compare(termsPolicy.definedVersion, termsPolicy.policyVersion, '<=')) {
      setValidDescription('It should be upgraded from the previous version.');
      setIsAlert(true);
      return;
    }
    // Display Order
    if (isEmpty(termsPolicy.displayOrder) || !isFinite(termsPolicy.displayOrder) || Number(termsPolicy.displayOrder) < 1) {
      setValidDescription('The display order was entered incorrectly. (number 1 or more)');
      setIsAlert(true);
      return;
    }
    // Display Option
    if (isEmpty(termsPolicy.displayOption) || !displayOption.includes(termsPolicy.displayOption)) {
      setValidDescription('Please select a display option');
      setIsAlert(true);
      return;
    }
    // country
    if (isEmpty(termsPolicy.countryCodeList) || isEmpty(termsPolicy.countryList)) {
      setValidDescription('Select the country this policy applies to.');
      setIsAlert(true);
      return;
    }
    // language
    if (isEmpty(termsPolicy.contentLanguageCodeList) || isEmpty(termsPolicy.contentList)) {
      setValidDescription('Select the language in which you want to write your policy.');
      setIsAlert(true);
      return;
    }

    let isValid = true;
    let msg = '';
    termsPolicy.contentList.forEach(f => {
      if (isEmpty(f.policyTitle)) {
        isValid = false;
        setValidDescription('Please enter a title for your [' + f.languageCode + '] policy.');
        return;
      } else if (isEmpty(f.policyDescription)) {
        isValid = false;
        setValidDescription('Please enter description for your [' + f.languageCode + '] policy.');
        return;
      }
    });
    if (!isValid) {
      setIsAlert(true);
      return;
    }
    // title
    // description
    let policyVersion = '';
    policyVersion = ''.concat(termsPolicy.policyMajorVersion, '.', termsPolicy.policyMinorVersion, '.', termsPolicy.policyPatchVersion);
    setTermsPolicy({ ...termsPolicy, ...{ policyVersion: policyVersion } });
    if (edit) {
      setSaveMethod('PUT');
      setConfirmDescription('Do you want to edit it?');
    }
    setIsSave(true);
  };

  const isEmpty = input => {
    if (typeof input === 'undefined' || input === null || input === 'null' || input === '' || input.length === 0 || (typeof input === 'string' && input.trim().length === 0) || (Array.isArray(input) && input.length === 0) || (typeof input === 'object' && !Object.keys(input).length)) {
      return true;
    }
    return false;
  };

  const isValidVersion = (previousVersion, inputVersion) => {
    if (isEmpty(previousVersion) || isEmpty(inputVersion) || !compareVersions.validate(previousVersion) || !compareVersions.validate(inputVersion) || !compareVersions.compare(previousVersion, inputVersion, '<=')) {
      return false;
    }
    return true;
  };

  const versionInputHandler = event => {
    event.preventDefault();
    let { name, value } = event.target;
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputVersion = isEmpty(value) ? '' : Number(value);
    if (name === 'policyMajorVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyMajorVersion: inputVersion,
          policyVersion: ''.concat(inputVersion, '.', termsPolicy.policyMinorVersion, '.', termsPolicy.policyPatchVersion),
        },
      });
    } else if (name === 'policyMinorVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyMinorVersion: inputVersion,
          policyVersion: ''.concat(termsPolicy.policyMajorVersion, '.', inputVersion, '.', termsPolicy.policyPatchVersion),
        },
      });
    } else if (name === 'policyPatchVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyPatchVersion: inputVersion,
          policyVersion: ''.concat(termsPolicy.policyMajorVersion, '.', termsPolicy.policyMinorVersion, '.', inputVersion),
        },
      });
    }
  };

  const saveTermsPolicy = () => {
    let body = { ...termsPolicy };
    fetch(`${API_BASE_URL}/admin/terms-policy`, {
      method: saveMethod,
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (getTermsPolicyList) {
            getTermsPolicyList('Successfully save !');
          }
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('saveTermsPolicy error: ', error);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {edit ? 'Edit' : 'Add'} {title}
        <IconButton aria-label="close" onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form>
        <DialogContent className={'pop_body'}>
          <div>
            {termsPolicy && (
              <TableContainer className={'tbl_wrap'}>
                <Table className={'tbl_row_typ1'}>
                  <tbody>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell width="15%">
                        <label className="is_required">Device Type</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="deviceType" id="deviceType" disabled={edit} required fullWidth value={termsPolicy.deviceType ? termsPolicy.deviceType : ''} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {deviceTypeList
                            .filter(f => f !== 'mobile')
                            .map(item => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                      <TableCell width="15%">
                        <label className="is_required">Platform</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select
                          name="devicePlatformCodeList"
                          id="devicePlatformCodeList"
                          disabled={edit && termsPolicy.deviceType !== 'mobile'}
                          required
                          fullWidth
                          multiple
                          value={termsPolicy.devicePlatformCodeList ? termsPolicy.devicePlatformCodeList : []}
                          onChange={event => termsPolicyInputChangeHandler(event)}>
                          {devicePlatform
                            .filter(f => f.type === termsPolicy.deviceType)
                            .map(item => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="is_required">comment</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <TextField name="policyComment" inputProps={{ maxLength: 250 }} fullWidth required margin="dense" variant="outlined" value={termsPolicy.policyComment} onChange={event => termsPolicyInputChangeHandler(event)} />
                      </TableCell>
                    </TableRow>
                    {edit && (
                      <TableRow>
                        <TableCell>Enable</TableCell>
                        <TableCell colSpan={2}>{termsPolicy.enableFlag}</TableCell>
                        <TableCell>Published</TableCell>
                        <TableCell colSpan={2}>{termsPolicy.publishedVersion ? termsPolicy.publishedFlag + ' (Released: ' + termsPolicy.publishedVersion + ')' : termsPolicy.publishedFlag}</TableCell>
                      </TableRow>
                    )}
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="is_required">version</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <TextField name="policyMajorVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyMajorVersion} onChange={event => versionInputHandler(event)} />
                        <span style={{ display: 'inline-block' }}>
                          <h4 style={{ margin: '8px' }}>.</h4>
                        </span>
                        <TextField name="policyMinorVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyMinorVersion} onChange={event => versionInputHandler(event)} />
                        <span style={{ display: 'inline-block' }}>
                          <h4 style={{ margin: '8px' }}>.</h4>
                        </span>
                        <TextField name="policyPatchVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyPatchVersion} onChange={event => versionInputHandler(event)} />
                      </TableCell>
                      <TableCell>
                        <label className="is_required">Update version</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <span style={{ color: isValidVersion(termsPolicy.definedVersion, termsPolicy.policyVersion, termsPolicy.upgradedVersionFlag) ? '#000' : 'red' }}>
                          <h5>{termsPolicy.policyVersion}</h5>
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="is_required">Display Order</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <TextField name="displayOrder" required fullWidth inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.displayOrder} onChange={event => termsPolicyInputChangeHandler(event)} />
                      </TableCell>
                      <TableCell>
                        <label className="is_required">Display Option</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="displayOption" id="displayOption" required fullWidth value={termsPolicy.displayOption} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {displayOption.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell width="15%">
                        <label className="is_required">country</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="countryCodeList" id="countryCodeList" required fullWidth value={termsPolicy.countryCodeList} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {countryDropDownList.map(item => (
                            <MenuItem key={item.countryCode} value={item.countryCode}>
                              {item.countryCode + '(' + item.defaultLanguage + ')'}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell width="15%">
                        <label className="is_required">language</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="contentLanguageCodeList" id="contentLanguageCodeList" required fullWidth multiple value={termsPolicy.contentLanguageCodeList} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {languageDropDownList.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%">
                      <TableCell width="10%">
                        <label className="is_required">Content</label>
                      </TableCell>
                      <TableCell width="90%" colSpan={5}>
                        <PolicyContent contentData={termsPolicy.contentList && termsPolicy.contentList.length > 0 ? termsPolicy.contentList : []} contentChangeHandler={contentChangeHandler} />
                      </TableCell>
                    </TableRow>
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={closeHandler} className={'btn_color1'} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="button" color="primary" onClick={confirmHandler} className={'btn_color2'} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <ConfirmDialog
        openDialog={isSave}
        description={confirmDescription}
        confirmHandler={() => {
          saveTermsPolicy();
        }}
        closeHandler={() => {
          setIsSave(false);
        }}
      />
      <AlertDialog
        openAlert={isAlert}
        description={validDescription}
        warningList={[]}
        closeHandler={() => {
          setIsAlert(false);
        }}
      />
    </Fragment>
  );
}

TermsPolicyForm.propTypes = {
  title: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
  closeHandler: PropTypes.func.isRequired,
  getTermsPolicyList: PropTypes.func,
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps)(TermsPolicyForm);
