import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Button , Switch } from '@material-ui/core';

export default function UpcomingSearch({ selectedTableRef, searchResultData, upcomingResult
                                        , searchResultChcheckedRow, isResultRow }) {

  const column = [
    { title: 'parentContentId', field: 'parentContentId', hidden: true },
    { title: 'providerIdx', field: 'providerIdx', hidden: true },
    { title: 'scheduleOrder', field: 'scheduleOrder', hidden: true },
    { title: 'contentType', field: 'contentType', hidden: true },
    { title: 'countryCode', field: 'countryCode', hidden: true },
    { title: 'id', field: 'contentId' },
    { title: 'name', field: 'contentName' },
    { title: 'cp', field: 'providerId', minWidth: 30},
    { title: 'image', field: 'imageUrl', render: rowData => <img src={rowData.imageUrl} style={{ width: 70, backgroundColor: 'black' }} />},
    { title: 'startDate', field: 'startDateTime', minWidth: 60},
    { title: 'endDate', field: 'endDateTime', minWidth: 60},
  ];

  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'}>
      <MaterialTable
        title=""
        columns={column}
        tableRef={selectedTableRef}
        data={searchResultData}
        options={{
          selection: true,
          showTextRowsSelected: false,
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 800,
          minBodyHeight: 800,
          rowStyle: rowData => ({
            backgroundColor: upcomingResult.find(resultRow => isResultRow(rowData, resultRow)) ? '#e0e0e0' : rowData.tableData.checked ? '#fef5f8' : '#FFF',
            color: rowData.tableData.checked ? '#d8295c' : '#484848',
          }),
          selectionProps: rowData => {
            const findCurrentRow = upcomingResult.find(resultRow => isResultRow(rowData, resultRow));
            rowData.tableData.disabled = findCurrentRow ? true : false;
            return {
              disabled: findCurrentRow ? true : false,
            };
          },
        }}
        onSelectionChange={searchResultChcheckedRow}
      />
    </div>
  );
}

UpcomingSearch.propTypes = {
  isResultRow: PropTypes.func.isRequired,
  searchResultChcheckedRow: PropTypes.func.isRequired,
  searchResultData: PropTypes.array.isRequired,
  upcomingResult: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
