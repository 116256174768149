import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableCell, TableContainer, TableRow, TextField, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ContentTabPanel from './ContentTabPanel';

const deviceList = ['mobile'];
const devicePlatformList = ['ALL', 'Android', 'IOS'];
const mandatoryFields = ['Y', 'N'];

function TermPolicyForm({ title, edit, data, regionCountries, handleInputChange, handleLangConentsChange, handleSubmit, handleClose, supportLanguages, handleInputNumberChange }) {
  /*
  const allCountries = (regionList.find(x => x.deviceType === 'pc') || { list: [] }).list.reduce((ac, a) => {
    return [...ac, ...a.cntry];
  }, []);
  */
  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {edit ? 'Edit' : 'Add'} {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
              <tbody>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Device Type</label>
                  </TableCell>
                  <TableCell colSpan={(data.deviceType  === 'mobile' )? 2 : 5}>
                    <Select name="deviceType" id="deviceType" required style={{ minWidth: 120 }} value={data.deviceType ? data.deviceType : ''} onChange={event => handleInputChange(event)}>
                      {[...deviceList].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  {(data.deviceType  === 'mobile' ) && (
                  <Fragment>
                  <TableCell>
                    <label className="is_required">Platform</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Select name="devicePlatform" id="devicePlatform" required style={{ minWidth: 120 }} value={data.devicePlatform ? (data.devicePlatform === 'Android,IOS' ? 'ALL' : data.devicePlatform) : ''} onChange={event => handleInputChange(event)}>
                      {[...devicePlatformList].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  </Fragment>
                  )}
                </TableRow>
                {(data.deviceType  === 'mobile' ) && (
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">version</label>
                  </TableCell>
                  <TableCell colSpan={5}>
                    <TextField fullWidth name="deviceVersion" required margin="dense" variant="outlined" value={data.deviceVersion} onChange={event => handleInputNumberChange(event)} />
                  </TableCell>
                </TableRow>
                )}
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">comment</label>
                  </TableCell>
                  <TableCell colSpan={5}>
                    <TextField fullWidth name="comment" required margin="dense" variant="outlined" value={data.comment} onChange={event => handleInputChange(event)} />
                  </TableCell>
                </TableRow>
                {(data.deviceType  !== 'mobile' ) && (
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Type Order</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField fullWidth type="number" name="typeOrder" required margin="dense" variant="outlined" value={data.typeOrder} onChange={event => handleInputChange(event)} />
                  </TableCell>
                  <TableCell>
                    <label className="is_required">Mandatory</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Select name="mandatory" id="mandatory" required style={{ minWidth: 120 }} value={data.mandatory ? data.mandatory : ''} onChange={event => handleInputChange(event)}>
                      {[...mandatoryFields].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                )}
                <TableRow width="100%">
                  <TableCell width="15%">
                    <label className="is_required">country</label>
                  </TableCell>
                  <TableCell>
                  <Select name="countryCode" id="countryCode" required style={{ minWidth: 120 }} value={data.countryCode} onChange={event => handleInputChange(event)} multiple>
                        {regionCountries.filter(n =>n.deviceType === data.deviceType).map(item => (
                          <MenuItem key={item.countryCode} value={item.countryCode}>
                            {item.countryCode}
                          </MenuItem>
                        ))}
                      </Select>
                  </TableCell>
                  <TableCell width="15%">
                    {' '}
                    <label className="is_required">language</label>
                  </TableCell>
                  <TableCell>
                    <Select name="lang" id="lang" required style={{ minWidth: 120 }} value={data.lang} onChange={event => handleInputChange(event)} multiple>
                      {[...supportLanguages].map(item => (
                        <MenuItem key={item.lang_code} value={item.lang_code}>
                          {item.lang_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <FormGroup row>
                      <FormControlLabel control={<Switch name="enable" checked={data.enable === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleInputChange(event)} />} label="enable" />
                    </FormGroup>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell width="10%">
                    <label className="is_required">Content</label>
                  </TableCell>
                  <TableCell width="90%" colSpan={5}>
                    {edit && (
                      <FormGroup row>
                        <FormControlLabel control={<Checkbox name="updateVersion" checked={data.updateVersion === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleInputChange(event)} />} label="Update Version" />
                      </FormGroup>
                    )}
                    <ContentTabPanel languages={data.lang} langContentsData={data.termMultiLangList ? data.termMultiLangList : []} handleContent={handleLangConentsChange}/>
                  </TableCell>
                </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

TermPolicyForm.propTypes = {
  title: PropTypes.string.isRequired,
  regionCountries: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLangConentsChange: PropTypes.func.isRequired,
  supportLanguages: PropTypes.array.isRequired,
  handleInputNumberChange: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
  supportLanguages: state.web.supportLanguages,
  regionCountries: state.cntry.regionCountries
});

export default connect(mapStateToProps)(TermPolicyForm);

