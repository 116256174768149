import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { getTabCategoryAction } from '../../actions/homeAppActions';
import { request } from '../../util/request';
import { isNaturalNum } from '../../util/util';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const categoryContentTypes = ['live', 'tvshow', 'movie', 'livestream', 'tvshow-series'];
function TabCategoryAdd({ tabId, maxCategoryCode, param, getTabCategoryAction, defaultReOrderCategoryRow, updateTabOrder, region }) {
  const [filter, setFilter] = React.useState({
    categoryCode: '',
    categoryName: '',
    categoryOrder: '',
    contentType: '',
    mappingType: '',
    categoryType: '',
    channelGenreName: '',
    genreCode: '',
  });

  const [open, setOpen] = React.useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name;
    setFilter({ ...filter, [inputId]: e.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    //if (!isNaturalNum(filter.categoryOrder)) alert('The order is not natural number ! Please enter natural number !');
    //else if (!filter.contentType) alert('The content type is empty ! Please select the content type');
    //else if (!filter.mappingType) alert('The mapping type is empty ! Please select the mapping type');
    //else
    if (filter.contentType.indexOf('tvshow-series') > -1 && filter.mappingType === 'auto') {
      alert('tvshow-series cannot be set auto mapping rule.');
    } else if ((filter.categoryType === 'upcoming' && filter.contentType.indexOf('livestream') > -1) ||
               (filter.categoryType === 'upcoming' && filter.contentType.indexOf('tvshow-series') > -1)) {
      alert('Upcoming is only available in live and normal VOD types.');
    } else if ((filter.categoryType === 'upcoming' && (filter.contentType.indexOf('live') > -1 && filter.contentType.indexOf('tvshow') > -1)) ||
               (filter.categoryType === 'upcoming' && (filter.contentType.indexOf('live') > -1 && filter.contentType.indexOf('movie') > -1))) {
      alert('Upcoming live and VOD cannot be set together.');
    } else {
      alert('Are you sure to add this category?');
      let categoryCodeName = 'C' + (parseInt(maxCategoryCode) + parseInt(1));
      if (filter.mappingType === 'slider') {
        categoryCodeName = 'C-SLIDER'
      }
      if (filter.mappingType === 'carousel') {
        categoryCodeName = 'C-CAROUSEL'
      }
      let data = [];
      data = defaultReOrderCategoryRow(tabId)
      if (data && data.length > 0) {
        updateTabOrder([...data], param.deviceType, param.region, param.platformVersion, param.status, false);
        let topArray = data.filter(n => n.categoryType === 'top');
        let topOrder = 0;
        if (topArray && topArray.length > 0) {
          topOrder = data.filter(n => n.categoryType === 'top').slice(-1)[0].categoryOrder;
        }

        let otherArray = data.filter(n => n.categoryType != 'top');
        let otherOrder = 10;
        if (otherArray && otherArray.length > 0) {
          otherOrder = data.filter(n => n.categoryType != 'top').slice(-1)[0].categoryOrder;
        }

        if (filter.categoryType === 'top') {
          filter.categoryOrder = topOrder + 1;
        } else {
          filter.categoryOrder = otherOrder + 1;
        }
      }
      const result = await request(`/api/category?tabId=${tabId}`, 'POST', { ...filter, categoryCode: categoryCodeName }, true);
      if (result.idx) {
        setSuccessSnackbarOpen(true);
        getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
        setOpen(false);
      } else {
        alert('Unknown error has occured !');
      }
    }
  };
  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)} className={'btn_size_tbl btn_color3'}>
        Add Category
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} aria-labelledby="form-dialog-title" className={'pop_wrap'}>
        <AddForm filter={filter} setFilter={setFilter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} region={region}/>
      </Dialog>
    </React.Fragment>
  );
}
TabCategoryAdd.propTypes = {
  tabId: PropTypes.number.isRequired,
  maxCategoryCode: PropTypes.number.isRequired,
  param: PropTypes.object.isRequired,
  getTabCategoryAction: PropTypes.func.isRequired,
  defaultReOrderCategoryRow: PropTypes.array.isRequired,
  updateTabOrder: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  param: state.homeApp.param,
});
const mapDispatchToProps = dispatch => ({
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TabCategoryAdd);

const AddForm = ({ handleClose, handleSubmit, filter, setFilter, handleInputChange, region }) => {
  const [channelGenreView, setChannelGenreView] = React.useState(false);
  const [genres, setGenres] = React.useState([]);

  const requestResult = async () => {
    const channelGenreResult = await request(`/api/category/channel-genre/${region}`, 'GET', null, true);
    setGenres(channelGenreResult);
  };

  React.useEffect(() => {
    if (region) {
      requestResult();
    }
  }, [region]);

  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        Add Category
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                {/* <col style={{ width: '100px' }} /> */}
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label htmlFor={'username'} className={'is_required'}>
                      Name
                    </label>
                  </th>
                  {/* <td>
                    <FormControl variant="outlined">
                      <InputLabel>Language</InputLabel>
                      <Select
                        value={filter.langCode}
                        onChange={e => {
                          setFilter({ ...filter, langCode: e.target.value });
                        }}>
                        {langList.map(n => (
                          <MenuItem key={n} value={n}>
                            {n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td> */}
                  <td colSpan="2">
                    <TextField fullWidth margin="dense" variant="outlined" name="categoryName" id="categoryName" value={filter.categoryName} onChange={e => handleInputChange(e)} disabled={channelGenreView} required autoFocus/>
                  </td>
                </tr>
                {/*
                <tr>
                  <th>
                    <label htmlFor={'password'} className={'is_required'}>
                      Category Order
                    </label>
                  </th>
                  <td colSpan="2">
                    <TextField fullWidth margin="dense" variant="outlined" name="categoryOrder" id="categoryOrder" value={filter.categoryOrder} onChange={e => handleInputChange(e)} required helperText="should be an integer (...-1,0,1,2...)" />
                  </td>
                </tr>
                */}
                <tr>
                  <th>
                    <label htmlFor={'password'}>
                      Content Type
                    </label>
                  </th>
                  <td colSpan="2">
                    <FormControl variant="outlined">
                      <Select
                        multiple
                        value={filter.contentType.split(',')}
                        onChange={e => {
                          setFilter({ ...filter, contentType: e.target.value.filter(n => n).join(',') });
                        }}>
                        {categoryContentTypes.map(n => (
                          <MenuItem key={n} value={n}>
                            {n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'password'} className={'is_required'}>
                      Category Type
                    </label>
                  </th>
                  <td colSpan="2">
                    <FormControl variant="outlined">
                      <Select
                        required
                        value={filter.categoryType}
                        onChange={e => {
                          if (e.target.value === 'channel_genre') {
                            setChannelGenreView(true);
                          } else {
                            setChannelGenreView(false);
                          }
                          setFilter({ ...filter, categoryType: e.target.value });
                        }}>
                        {/* 20250117 upcoming 제거 */}
                        {['common', 'top', 'rank', 'recent', 'favorite', 'channel_genre', 'genre', 'livestream'].map(n => (
                          <MenuItem key={n} value={n}>
                            {n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
                {channelGenreView ?
                (
                  <tr>
                  <th>
                    <label htmlFor={'password'} className={'is_required'}>
                      Channel Genre
                    </label>
                  </th>
                  <td colSpan="2">
                    <FormControl variant="outlined">
                      <Select
                        required
                        value={filter.channelGenreName}
                        onChange={e => {
                            setFilter({ ...filter, channelGenreName: e.target.value, categoryName: e.target.value, genreCode: genres.find(n => n.genreName === e.target.value).genreCode });
                        }}>
                        {genres.map(n => (
                          <MenuItem key={n.genreCode} value={n.genreName}>
                            {n.genreText}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
                )
                :
                (
                  <tr></tr>
                )
                }
                <tr>
                  <th>
                    <label htmlFor={'password'}>
                      Mapping Type
                    </label>
                  </th>
                  <td colSpan="2">
                    <FormControl variant="outlined">
                      <Select
                        value={filter.mappingType}
                        onChange={e => {
                          setFilter({ ...filter, mappingType: e.target.value });
                        }}>
                        {['auto', 'manual', 'slider', 'carousel'].map(n => (
                          <MenuItem key={n} value={n}>
                            {n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button data-testid="addButton" type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
};
