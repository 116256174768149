import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Dialog, Switch } from '@material-ui/core';
import TermPolicyForm from './TermPolicyForm';
import { getTermsAction, addTermsAction, updateTermsAction, publishTermsAction, getSupportLanguagesAction, termsPublishAction } from '../../actions/webActions';
import TermPolicyView from './TermPolicyView';
import TermPolicyHistory from './TermPolicyHistory';

function TermsofUse({ getTerms, terms, addTerm, updateTerm, publishTerm, getSupportLanguages, termsPublish }) {
  useEffect(() => {
    getTerms();
    getSupportLanguages();
  }, []);

  const [open, setOpen] = useState(false);
  const defaultPopupType = {
    add: false,
    edit: false,
    preview: false,
    history: false,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);

  const defaultData = {
    type: 'termsOfUse',
    comment: '',
    version: '',
    countryCode: [],
    lang: [],
    termMultiLangList: [],
    enable: 'N',
    typeOrder: '',
    mandatory: '',
    deviceType: '',
    devicePlatform: '',
    deviceVersion: '',
  };

  const [term, setTerm] = useState({});

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType' ) {
      setTerm({ ...term, ...{ [name]: value, countryCode: [], typeOrder: '',  mandatory: '', devicePlatform: '' } });
    } else if (name === 'enable' || name === 'updateVersion') {
      setTerm({ ...term, ...{ [name]: e.target.checked ? 'Y' : 'N' } });
    } else {
      setTerm({ ...term, ...{ [name]: value } });
    }
  };

  const handleLangConentsChange = langData => {
    setTerm({ ...term, ...{ termMultiLangList: langData } });
  };

  const handleInputNumberChange = e => {
    const { name, value } = e.target;
    const onlyNumber = value.replace(/[^-/.0-9]/g, '');
    setTerm({ ...term, ...{ [name]: onlyNumber } });
  };
  const handleSubmit = e => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    if (popupType.edit) {
      updateTerm(term);
    } else {
      addTerm(term);
    }
    setOpen(false);
  };

  const handlePublish = e => {
    e.preventDefault();
    publishTerm(defaultData.type);
  };

  const getTermData = rowData => {
    setOpen(true);
    setTerm(rowData);
  };

  const handleIndividualPublish = id => {
    termsPublish(id);
  };
  return (
    <Fragment>
      <div className="terms_area tbl_wrap">
        <div className={'btn_set_wrap tbl_top1'}>
          <Button
            color="secondary"
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              // ==== add Popup
              setOpen(true);
              setPopupType({ ...defaultPopupType, add: true });
              setTerm(defaultData);
            }}>
            Add
          </Button>
          <Button color="secondary" variant="contained" className={'btn_color2'} onClick={handlePublish}>
            Publish
          </Button>
        </div>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'deviceType',
              field: 'deviceType',
              render: rowData => (rowData.deviceType),
            },
            {
              title: 'country',
              field: 'countryCode',
              render: rowData => (rowData.newCountryCode ? rowData.newCountryCode : rowData.countryCode),
            },
            {
              title: 'version',
              render: rowData => (
                <button
                  onClick={() => {
                    getTermData(rowData);
                    setPopupType({ ...defaultPopupType, preview: true });
                  }}>
                  <span className="link">{rowData.version}</span>
                </button>
              ),
            },
            {
              title: 'enable',
              field: 'enable',
              render: rowData => <Switch checked={rowData.enable === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} />,
            },
            {
              title: 'comment',
              field: 'comment',
            },
            {
              title: 'lastChgDate',
              field: 'lastChgDate',
            },
            {
              title: 'action',
              field: 'Action',
              width: '10%',
              render: rowData => (
                <div className={'btn_set_wrap_tbl'}>
                  <Button
                    variant="contained"
                    disabled={rowData.deviceType === 'pc'}
                    onClick={() => {
                      if(rowData.deviceType !== 'pc'){
                        setOpen(true);
                        setPopupType({ ...defaultPopupType, edit: true });
                        // adding languges
                        const term = { ...rowData };
                        term['countryCode'] = term.countryCode.split(',');
                        term['newCountryCode'] = term.newCountryCode ? term.newCountryCode.split(',') : term.countryCode;
                        term['updateVersion'] = '';
                        term['lang'] = term.termMultiLangList ? term.termMultiLangList.map(x => x.langCode) : [];
                        setTerm(term);
                      }
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                      setPopupType({ ...defaultPopupType, history: true });
                      const term = { ...rowData };
                      term['countryCode'] = term.countryCode.split(',');
                      term['lang'] = term.termMultiLangList ? term.termMultiLangList.map(x => x.langCode) : [];
                      setTerm(term);
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    History
                  </Button>
                  {(rowData.enable  === 'Y' ) && (
                  <Button variant="contained" className={'btn_color2 btn_size_tbl'} onClick={() => handleIndividualPublish(rowData.idx)}>
                    Publish
                  </Button>
                  )}
                </div>
              ),
            },
          ]}
          data={terms}
          options={{
            paging: false,
            search: true,
            rowStyle: { backgroundColor: '#fff' },
          }}
        />
      </div>
      <Dialog open={open} className={'pop_wrap'}>
        {popupType.preview && <TermPolicyView type={'termsOfUse'} data={term} title="Terms Of Use" handleClose={() => setOpen(false)} />}
        {(popupType.add || popupType.edit) && <TermPolicyForm edit={popupType.edit} data={term} title="Terms Of Use" handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} handleLangConentsChange={handleLangConentsChange} handleInputNumberChange={handleInputNumberChange}/>}
        {popupType.history && <TermPolicyHistory type={'termsOfUse'} title="Terms Of Use" handleClose={() => setOpen(false)} historyId={term.historyId} />}
      </Dialog>
    </Fragment>
  );
}
TermsofUse.propTypes = {
  terms: PropTypes.array.isRequired,
  getTerms: PropTypes.func.isRequired,
  addTerm: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  publishTerm: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  termsPublish: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  terms: state.web.terms,
});

const mapDispatchToProps = dispatch => ({
  getTerms: () => dispatch(getTermsAction()),
  addTerm: data => dispatch(addTermsAction(data)),
  updateTerm: data => dispatch(updateTermsAction(data)),
  publishTerm: data => dispatch(publishTermsAction(data)),
  getSupportLanguages: () => dispatch(getSupportLanguagesAction()),
  termsPublish: id => dispatch(termsPublishAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TermsofUse));
