import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Tab, Tabs, TextareaAutosize, TextField } from '@material-ui/core';

function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
}

function PolicyContent({ contentData, contentChangeHandler }) {
  const [tabValue, setTabValue] = useState('');

  useEffect(() => {
    if (contentData && contentData.length > 0) {
      if (!contentData.map(content => content.languageCode).includes(tabValue)) {
        setTabValue(contentData[0].languageCode);
      }
    } else {
      setTabValue('');
    }
  }, [contentData]);

  const tabChangeHandler = (event, value) => {
    event.preventDefault();
    setTabValue(value);
  };

  const descriptionChangeHandler = (event, lang) => {
    const { value } = event.target;
    const writeContentData = contentData.map(content => {
      if (content.languageCode === lang) {
        return { ...content, policyDescription: value };
      }
      return content;
    });
    contentChangeHandler(writeContentData);
  };

  const titleChangeHandler = (event, language) => {
    const { value } = event.target;
    const writeContentData = contentData.map(content => {
      if (content.languageCode === language) {
        return { ...content, policyTitle: value };
      }
      return content;
    });
    contentChangeHandler(writeContentData);
  };

  return (
    <Fragment>
      {contentData && contentData.map(content => content.languageCode).length > 0 && (
        <Fragment>
          <AppBar position="static" id="scheduleTabHead">
            {tabValue && (
              <Tabs value={tabValue} onChange={tabChangeHandler}>
                {contentData.map(content => (
                  <Tab key={content.languageCode} value={content.languageCode} label={content.languageCode} {...a11yProps(content.languageCode)} />
                ))}
              </Tabs>
            )}
          </AppBar>
          {contentData.map((content, index) => (
            <div key={index} role="tabpanel" hidden={tabValue !== content.languageCode} id={`simple-tabpanel-${content.languageCode}`} aria-labelledby={`simple-tab-${content.languageCode}`}>
              {
                <div>
                  <TextField label="Title" fullWidth required autoFocus margin="dense" variant="outlined" name="title" id={`title-${content.languageCode}`} value={content.policyTitle} onChange={event => titleChangeHandler(event, content.languageCode)} />
                  <br />
                  <TextareaAutosize
                    name="description"
                    required
                    placeholder={`Add Description for ${content.languageCode}`}
                    id={`description-${content.languageCode}`}
                    variant="outlined"
                    aria-label="minimum height"
                    color="currentColor"
                    rowsMin={10}
                    className="tbl_textarea"
                    value={content.policyDescription}
                    onChange={event => descriptionChangeHandler(event, content.languageCode)}
                  />
                  <p className="check_bite"></p>
                </div>
              }
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

PolicyContent.propTypes = {
  contentData: PropTypes.array.isRequired,
  contentChangeHandler: PropTypes.func.isRequired,
};

export default connect()(PolicyContent);
