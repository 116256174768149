import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function AlertDialog({ openAlert, description, warningList, closeHandler }) {
  return (
    <div>
    <Dialog open={openAlert} onClose={closeHandler} aria-labelledby="form-dialog-title" className={'pop_wrap publish_pop'}>
      <Dialog open={openAlert} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <p className="pop_comment" id="AlertDialog">{description}</p>
          {(warningList && warningList.length > 0) && (
          <div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="MuiDialogActions-root">
              {warningList.map((warning, index) => ( <span key={index}>{warning}</span> ))}
            </DialogContentText>
          </DialogContent>
          </div>
          )}
          <DialogActions>
            <div className="btn_set_wrap alC">
              <Button color="primary" onClick={closeHandler} className={'btn_color2'}>OK</Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </Dialog>
  </div>
  );
}

AlertDialog.propTypes = {
  openAlert: PropTypes.bool.isRequired,
  description: PropTypes.string,
  warningList: PropTypes.array,
  closeHandler: PropTypes.func,
};
