import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function UpcomingSearchFilter({ searchData,  setSearchData, filterData }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [providerList, setProviderList] = useState([]);

  useEffect(() => {
    if (searchData.countryCode !== ''){
      if (searchData.contentType === 'live') {
        setProviderList(filterData.countryList.find(f => f.countryCode === searchData.countryCode).channelProviderList.map(m => m.channelProviderId));
      } else if (searchData.contentType === 'movie' || searchData.contentType === 'tvshow') {
        setProviderList(filterData.countryList.find(f => f.countryCode === searchData.countryCode).vodProviderList.map(m => m.vodProviderId));
      } else {
        setProviderList([]);
      }
    }
  }, [ searchData.contentType ]);

  const handleSearchFilter = e => {
    const { name, value } = e.target;
    if (name === 'contentType') {
      setSearchData({ ...searchData, ...{ countryCode: searchData.countryCode, [name]: value, provider: '' } });
    } else {
      setSearchData({ ...searchData, ...{ countryCode: searchData.countryCode, contentType: searchData.contentType, [name]: value } });
    }
  };

  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select name="countryCode" id="countryCode" value={searchData.countryCode} onChange={e => handleSearchFilter(e)}>
            {filterData.countryList.map(n => (
              <MenuItem key={n.countryCode} value={n.countryCode}>
                {n.countryCode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>content Type</InputLabel>
          <Select name="contentType" id="contentType" value={searchData.contentType} onChange={e => handleSearchFilter(e)} disabled={isDisabled}>
          {filterData.contentTypeList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>provider</InputLabel>
          <Select name="provider" id="provider" value={searchData.provider ? searchData.provider : 'ALL'} onChange={e => handleSearchFilter(e)} disabled={isDisabled}>
            {['ALL',  ...providerList].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
}
UpcomingSearchFilter.propTypes = {
  filterData: PropTypes.object.isRequired,
  searchData: PropTypes.object.isRequired,
  setSearchData: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  homeAppResult: state.homeApp.manualResultList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpcomingSearchFilter));
