/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';
//import { request } from '../../../../util/request';
import { API_BASE_URL } from '../../../../constants';
import { getHeaders } from '../../../../util/actionUtil';
import CommonDialog from '../../../../common/dialog/CommonDialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, NativeSelect } from '@material-ui/core';
import MaterialTable, { MTableBodyRow } from 'material-table';

function CarouselResult({ resultTableRef, history, carouselIdx, countryCode, carouselResult, setCarouselResult, carouselResultCheckedRow, getCarouselResult, getCarouselResetResult, onClickAdd, onClickDelete, resultView, hubTabView }) {
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;
  const [isSaved, setIsSaved] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const getImage = image => {
    if (image == null || image === '') {
      return '';
    } else if (image.indexOf('http') > -1) {
      return <img alt="invalid" src={image} title={image} style={{ backgroundColor: 'black', maxWidth: 100, maxHeight: 100, border: '1px solid #cbccce' }} />;
    } else {
      return <img alt="invalid" src={prefix + image} title={image} style={{ backgroundColor: 'black', maxWidth: 100, maxHeight: 100, border: '1px solid #cbccce' }} />;
    }
  };

  const clickSave = async e => {
    e.preventDefault();
    if (carouselResult.length < 1) {
      alert('The number of result is less than 1 ! Please add at lease one content !');
    } else if (carouselResult.find(n => n.result_order === '')) {
      alert('Some order is empty ! Please enter the order !');
    } else if (carouselResult.length > 10) {
      alert('Cannot exceed 10 content.');
    } else if (carouselResult.find(n => n.carouselType === 'notification' && (n.resultHorizontalImage == null || n.resultVerticalImage == null || n.resultHorizontalImage == '' || n.resultVerticalImage == ''))) {
      let click = carouselResult.find(n => n.carouselType === 'notification' && (n.resultHorizontalImage == null || n.resultVerticalImage == null || n.resultHorizontalImage == '' || n.resultVerticalImage == ''));
      alert(`You need to upload an image to save the notification.[${click.contentId}]`);
    } else if (carouselResult.find(n => n.carouselType === 'hub' && (n.resultHorizontalImage == null || n.resultVerticalImage == null || n.resultHorizontalImage == '' || n.resultVerticalImage == ''))) {
      let click = carouselResult.find(n => n.carouselType === 'hub' && (n.resultHorizontalImage == null || n.resultVerticalImage == null || n.resultHorizontalImage == '' || n.resultVerticalImage == ''));
      alert(`You need to upload an image to save the hub.[${click.contentId}]`);
    } else if (carouselResult.find(n => n.carouselType === 'hub' && (n.resultTitle == null || n.resultDescription == null || n.resultTitle == '' || n.resultDescription == ''))) {
      let click = carouselResult.find(n => n.carouselType === 'hub' && (n.resultTitle == null || n.resultDescription == null || n.resultTitle == '' || n.resultDescription == ''));
      alert(`To save your hub[${click.contentId}], you will need a title and description.`);
    } else {
      setIsSaved(true);
    }
  };

  const showResultView = async e => {
    e.preventDefault();
    resultView();
  };

  const showHubTextView = async e => {
    e.preventDefault();
    hubTabView();
  };

  const confirmResetResult = async e => {
    e.preventDefault();
    setIsReset(true);
  };

  const resetResult = () => {
    setIsReset(false);
    getCarouselResetResult(carouselIdx, countryCode);
  };

  const saveCarouselResult = () => {
    const carouselResultList = carouselResult.map(n => {
      const { carouselIdx, carouselId, countryCode, providerIdx, contentId, tabCode, carouselType, contentType, resultOrder, manualFlag, resultHorizontalImage, resultVerticalImage, resultTitle, resultDescription } = n;
      let result = {
        carouselIdx: carouselIdx,
        carouselId: carouselId,
        countryCode: countryCode,
        providerIdx: providerIdx,
        contentId: contentId,
        tabCode: tabCode,
        carouselType: carouselType,
        contentType: contentType,
        resultOrder: resultOrder,
        manualFlag: manualFlag,
        resultHorizontalImage: resultHorizontalImage,
        resultVerticalImage: resultVerticalImage,
        resultTitle: resultTitle,
        resultDescription: resultDescription,
      };
      return result;
    });

    fetch(`${API_BASE_URL}/carousel/${carouselIdx}/country/${countryCode}/result`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(carouselResultList),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          if (body.message) {
            alert(body.message);
          } else {
            alert('Some Error Occurred !');
          }
        } else {
          alert('Successfully save !');
          getCarouselResult(carouselIdx, countryCode);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsSaved(false);
      });
  };

  const [order, setOrder] = React.useState({ key: '', type: '' });
  const dragState = {
    row: -1,
    dropIndex: -1, // drag target
    position: -1,
    dragPosition: -1,
    type: '',
    start: -1,
    end: -1,
  };

  //Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to, type) => {
    let newtableData = [];
    if (type === 'Swap') {
      [carouselResult[from], carouselResult[to]] = [carouselResult[to], carouselResult[from]];
      let start = carouselResult[0],
        between = carouselResult.slice(1, carouselResult.length - 1),
        end = carouselResult[carouselResult.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      newtableData = offsetIndex(from, to, carouselResult);
    } else {
      return;
    }
    newtableData.map((element, index) => (element.resultOrder = index + 1));
    setCarouselResult(newtableData);
  };
  const manualHandle = (event, row) => {
    event.preventDefault();
    const updateList = [];
    if (row.carouselType === 'more' || row.carouselType === 'notification' || row.carouselType === 'event') {
      alert('Content of this type cannot be unlocked. ex) more, event, notification');
      return;
    }
    carouselResult.map(element => {
      if (element.contentId === row.contentId) {
        //const enable = event.target.checked ? 'Y' : 'N';
        element.manualFlag = event.currentTarget.value;
      }
      updateList.push(element);
    });
    setCarouselResult(updateList);
  };
  const column = [
    { title: 'carouselIdx', field: 'carouselIdx', hidden: true },
    { title: 'carouselId', field: 'carouselId', hidden: true },
    { title: 'countryCode', field: 'countryCode', hidden: true },
    { title: 'providerIdx', field: 'providerIdx', hidden: true },
    { title: 'tabCode', field: 'tabCode', hidden: true },
    { title: 'order', field: 'resultOrder', minWidth: 60, mixWidth: 60 },
    { title: 'manualFlag', field: 'manualFlag', hidden: true },
    { title: 'carousel', field: 'carouselType', minWidth: 60 },
    { title: 'type', field: 'contentType', minWidth: 60 },
    { title: 'cp', field: 'providerId', hidden: true },
    { title: 'id', field: 'contentId', minWidth: 70 },
    { title: 'name', field: 'contentName', minWidth: 70 },
    {
      title: 'fix',
      render: row => (
        <NativeSelect value={row.manualFlag} onChange={event => manualHandle(event, row)}>
          <option value="Y">Y</option>
          <option value="N">N</option>
        </NativeSelect>
      ),
      mixWidth: 40,
    },
    { title: 'image', render: n => (n.resultHorizontalImage ? getImage(n.resultHorizontalImage) : getImage(n.image)), mixWidth: 110 },
    { title: 'vertical', render: n => (n.resultVerticalImage ? getImage(n.resultVerticalImage) : getImage(n.verticalImage)), mixWidth: 110 },
    { title: 'resultHorizontalImage', field: 'resultHorizontalImage', hidden: true },
    { title: 'resultVerticalImage', field: 'resultVerticalImage', hidden: true },
    { title: 'genre', field: 'genre', hidden: true },
  ];

  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span id="popup"></span>
        <MaterialTable
          tableRef={resultTableRef}
          resultTableRef
          title=""
          columns={column}
          onOrderChange={(key, type) => {
            setOrder({ key: `${key}`, type });
          }}
          components={{
            Row: props => (
              <MTableBodyRow
                {...props}
                draggable="true"
                onDragStart={e => {
                  dragState.row = props.data.tableData.id;
                  dragState.start = e.target.getBoundingClientRect().top;
                }}
                onDragEnter={e => {
                  e.preventDefault();
                  if (props.data.tableData.id !== dragState.row) {
                    dragState.dropIndex = props.data.tableData.id;
                    dragState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                    document.getElementById('popup').style.display = 'block';
                  }
                  dragState.end = e.target.parentElement.getBoundingClientRect().top;
                  if (dragState.start == dragState.end) {
                    document.getElementById('popup').style.display = 'none';
                  }
                }}
                onDragOver={e => {
                  dragState.dragPosition = e.clientY;
                  document.getElementById('popup').clientY = e.clientY;
                  document.getElementById('popup').innerHTML = 'Insert';
                  dragState.type = document.getElementById('popup').innerHTML;
                }}
                onDragEnd={e => {
                  if (dragState.dropIndex !== -1 && dragState.start != dragState.end) {
                    reOrderRow(dragState.row, dragState.dropIndex, dragState.type);
                  }
                  dragState.row = -1;
                  dragState.dropIndex = -1;
                  dragState.position = -1;
                  dragState.dragPosition = -1;
                  dragState.type = '';
                  dragState.start = -1;
                  dragState.end = -1;
                  document.getElementById('popup').style.display = 'none';
                }}
              />
            ),
          }}
          data={carouselResult}
          options={{
            search: false,
            selection: true,
            showTextRowsSelected: false,
            isLoading: true,
            paging: false,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 800,
            minBodyHeight: 800,
            //showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.manualFlag === 'Y' ? '#fef5f8' : '#FFF',
            }),
          }}
          onSelectionChange={carouselResultCheckedRow}
        />
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button className="btn_color2" onClick={showHubTextView}>
                HUB TEXT
              </Button>
              <Button className="btn_color2" onClick={showResultView}>
                UPLOAD IMAGE
              </Button>
              <Button className="btn_color2" onClick={confirmResetResult}>
                RESET
              </Button>
              <Button className="btn_color2" onClick={clickSave}>
                save
              </Button>
              <Button
                className="btn_color1"
                onClick={() => {
                  history.push('/HOME-APP/tab');
                }}>
                Back
              </Button>
            </div>
          </form>
        </React.Fragment>
      </div>
      <CommonDialog
        open={isSaved}
        description="Do you want to save the content?"
        handleCancel={() => {
          setIsSaved(false);
        }}
        handleOk={() => {
          saveCarouselResult();
        }}
      />
      <CommonDialog
        open={isReset}
        description="The images, order, and fixation of unsaved content disappear. Do you still want to proceed ?"
        handleCancel={() => {
          setIsReset(false);
        }}
        handleOk={() => {
          resetResult();
        }}
      />
    </React.Fragment>
  );
}
CarouselResult.propTypes = {
  countryCode: PropTypes.string.isRequired,
  carouselResult: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarouselResult));
