import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, Typography } from '@material-ui/core';
import CommonDialog from '../../../common/dialog/CommonDialog';
import CarouselSearch from './carousel/CarouselSearch';
import CarouselResult from './carousel/CarouselResult';
import SearchFilter from './carousel/SearchFilter';
import EventForm from './carousel/CarouselEventForm';
import ResultViewForm from './carousel/CarouselResultViewForm';
import ResultTextViewForm from './carousel/CarouselResultTextViewForm';

function Carousel({ id }) {
  const selectedTableRef = useRef();
  const resultTableRef = useRef();
  const carouselTypeList = ['channel', 'vod', 'more', 'notification', 'event'];
  // TODO
  //const carouselTypeList = ['channel', 'vod', 'more', 'hub', 'notification', 'event'];
  const [carousel, setCarousel] = useState({});
  const defaultSearchData = {
    countryCode: '',
    carouselType: '',
    contentType: '',
    provider: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const [searchCarouselType, setSearchCarouselType] = useState(carouselTypeList[0]);
  const [searchContentType, setSearchContentType] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [carouselResult, setCarouselResult] = useState([]);
  const [isEventButton, setIsEventButton] = useState(false);
  const [addData, setAddData] = React.useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const [searchFetch, setSearchFetch] = useState(false);
  const [resultFetch, setResultFetch] = useState(false);
  const defaultPopupType = {
    add: false,
    preview: false,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [uploadPopupType, setUploadPopupType] = useState(defaultPopupType);
  const [descPopupType, setDescPopupType] = useState(defaultPopupType);
  const defaultSearchFilterData = {
    countryList: [],
    carouselTypeList: [],
    contentTypeList: [],
  };
  const [filterData, setFilterData] = useState(defaultSearchFilterData);
  const defaultEventData = {
    bannerId: '',
    title: '',
    horizontalImage: '',
    verticalImage: '',
    focusImage: '',
  };
  const [eventBanner, setEventBanner] = useState(defaultEventData);
  const [isBannerDeploy, setIsBannerDeploy] = useState(false);
  const [isBannerDelete, setIsBannerDelete] = useState(false);
  const [deployData, setDeployData] = useState([]);
  const [resultViewData, setResultViewData] = useState();

  useEffect(() => {
    fetch(`${API_BASE_URL}/category/${id}/carousel`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setCarousel(body.info);
          const contentType = body.info.contentType.split(',');
          setFilterData({ countryList: body.info.countryList, carouselTypeList: carouselTypeList, contentTypeList: contentType });
          setSearchData({ ...searchData, ...{ countryCode: body.info.countryList[0].countryCode, carouselType: 'channel', contentType: 'live', provider: '' } });
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      });
  }, [id]);

  useEffect(() => {
    if (searchData.carouselType === 'event') {
      setIsEventButton(true);
    } else {
      setIsEventButton(false);
    }
  }, [searchData]);

  useEffect(() => {
    if (carousel.carouselIdx && searchData.countryCode !== '') {
      getCarouselResult(carousel.carouselIdx, searchData.countryCode);
      setSearchResult([]);
    }
  }, [searchData.countryCode]);

  const searchList = () => {
    searchCarouselData(carousel.carouselIdx, searchData);
  };

  const searchCarouselData = (carouselIdx, search) => {
    setSearchFetch(true);
    fetch(`${API_BASE_URL}/carousel/${carouselIdx}/country/${search.countryCode}/carousel-type/${search.carouselType}?contentType=${search.contentType}&provider=${search.provider}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setSearchResult(body.list);
          setSearchCarouselType(search.carouselType);
          setSearchContentType(search.contentType);
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      })
      .finally(() => {
        setSearchFetch(false);
      });
  };

  const getCarouselResult = (carouselIdx, countryCode) => {
    setResultFetch(true);
    fetch(`${API_BASE_URL}/carousel/${carouselIdx}/country/${countryCode}/result`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setCarouselResult(body.list);
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      })
      .finally(() => {
        setResultFetch(false);
      });
  };
  const getCarouselResetResult = (carouselIdx, countryCode) => {
    setResultFetch(true);
    fetch(`${API_BASE_URL}/carousel/${carouselIdx}/country/${countryCode}/result/reset`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setCarouselResult(body.list);
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      })
      .finally(() => {
        setResultFetch(false);
      });
  };
  const isResultRow = (searchRow, resultRow) => {
    if (searchRow.carouselType === 'channel') {
      return searchRow.contentId === resultRow.contentId && searchRow.providerId === resultRow.providerId;
    } else if (searchRow.carouselType === 'vod') {
      return searchRow.contentId === resultRow.contentId || (searchRow.seasonId !== null && resultRow.seasonId !== null && searchRow.seasonId === resultRow.seasonId && searchRow.providerId === resultRow.providerId);
    } else if (searchRow.carouselType === 'more') {
      return searchRow.carouselType === resultRow.carouselType && searchRow.contentId === resultRow.contentId && searchRow.tabCode === resultRow.tabCode;
    } else if (searchRow.carouselType === 'hub' && searchRow.contentType === 'tab') {
      return searchRow.contentId === resultRow.contentId;
    } else if (searchRow.carouselType === 'hub' && searchRow.contentType === 'category') {
      return searchRow.carouselType === resultRow.carouselType && searchRow.contentId === resultRow.contentId && searchRow.tabCode === resultRow.tabCode;
    } else {
      return searchRow.contentId === resultRow.contentId;
    }
  };

  const searchResultCheckedRow = rows => {
    if (rows.find(searchRow => carouselResult.find(resultRow => isResultRow(searchRow, resultRow)))) {
      //alert('content is already exist !');
      //selectedTableRef.current.onAllSelected(false);
    } else {
      let addOrderNumber = carouselResult.length + 1;
      setAddData(
        rows.map((n, i) => {
          const { carouselId, carouselIdx, carouselType, categoryType, contentId, contentName, contentType, countryCode, genre, image, verticalImage, manualFlag, providerId, providerIdx, seasonId, tabCode, tabName, eventPublishedFlag, resultTitle, resultDescription } = n;
          return {
            carouselId: carouselId,
            carouselIdx: carouselIdx,
            carouselType: carouselType,
            categoryType: categoryType,
            contentId: contentId,
            contentName: contentName,
            contentType: contentType,
            countryCode: countryCode,
            genre: genre,
            image: image,
            verticalImage: verticalImage,
            manualFlag: manualFlag,
            providerId: providerId,
            providerIdx: providerIdx,
            resultOrder: addOrderNumber + i,
            seasonId: seasonId,
            tabCode: tabCode,
            tabName: tabName,
            eventPublishedFlag: eventPublishedFlag,
            resultTitle: resultTitle,
            resultDescription: resultDescription,
          };
        }),
      );
    }
  };

  const carouselResultCheckedRow = rows => {
    setDeleteData(rows);
  };

  const resultView = () => {
    if (deleteData.length !== 1) {
      alert('You can select only one content to upload an image. !');
      return;
    }
    if (deleteData[0].manualFlag !== 'Y') {
      alert('Only fixed content can be uploaded. !');
      return;
    }
    if (deleteData[0].carouselType === 'event') {
      alert('The event cannot register an image. !');
      return;
    }
    setResultViewData(deleteData[0]);
    setUploadPopupType({ ...defaultPopupType, preview: true });
  };

  const hubTabViewCheck = () => {
    if (deleteData.length !== 1) {
      alert('You can select only one content to upload an image. !');
      return;
    }
    if (deleteData[0].manualFlag !== 'Y') {
      alert('Only fixed content can be uploaded. !');
      return;
    }
    if (deleteData[0].carouselType !== 'hub') {
      alert('Can only be written as type [Hub]. !');
      return;
    }
    setResultViewData(deleteData[0]);
    setDescPopupType({ ...defaultPopupType, preview: true });
  };

  const resetResultCheckBox = () => {
    setDeleteData([]);
    resultTableRef.current.onAllSelected(false);
  };

  const addResult = () => {
    if (addData.find(searchRow => carouselResult.find(resultRow => isResultRow(searchRow, resultRow)))) {
      alert('content is already exist !2');
      selectedTableRef.current.onAllSelected(false);
    } else if (addData.find(searchRow => searchRow.eventPublishedFlag === 'N')) {
      alert('Only events applied to the RS server can be organized. !');
    } else {
      selectedTableRef.current.onAllSelected(false);
      setCarouselResult(carouselResult.concat(addData));
      setAddData([]);
    }
  };

  const removeResult = () => {
    const copyResult = carouselResult.filter(resultRow => !deleteData.find(deleteRow => deleteRow.carouselType === resultRow.carouselType && deleteRow.contentId === resultRow.contentId));
    setCarouselResult(
      copyResult.map((n, i) => {
        const { carouselId, carouselIdx, carouselType, categoryType, contentId, contentName, contentType, countryCode, genre, image, manualFlag, providerId, providerIdx, seasonId, tabCode, tabName, resultHorizontalImage, resultVerticalImage, resultTitle, resultDescription } = n;
        return {
          carouselId: carouselId,
          carouselIdx: carouselIdx,
          carouselType: carouselType,
          categoryType: categoryType,
          contentId: contentId,
          contentName: contentName,
          contentType: contentType,
          countryCode: countryCode,
          genre: genre,
          image: image,
          manualFlag: manualFlag,
          providerId: providerId,
          providerIdx: providerIdx,
          resultOrder: 1 + i,
          seasonId: seasonId,
          tabCode: tabCode,
          tabName: tabName,
          resultHorizontalImage: resultHorizontalImage,
          resultVerticalImage: resultVerticalImage,
          resultTitle: resultTitle,
          resultDescription: resultDescription,
        };
      }),
    );
    setDeleteData([]);
  };

  const createEvent = () => {
    setPopupType({ ...defaultPopupType, add: true });
  };

  const previewEvent = (bannerId, contentTitle, horizontalImage, verticalImage, focusImage, deepLink, linkContentType, linkContentId) => {
    setPopupType({ ...defaultPopupType, preview: true });
    setEventBanner({ bannerId: bannerId, title: contentTitle, horizontalImage: horizontalImage, verticalImage: verticalImage, focusImage: focusImage, deepLink: deepLink, linkContentType: linkContentType, linkContentId: linkContentId });
  };

  const closedEvent = () => {
    setPopupType(defaultPopupType);
    setUploadPopupType(defaultPopupType);
    setDescPopupType(defaultPopupType);
  };

  const publishEventConfirm = () => {
    if (addData.find(searchRow => searchRow.carouselType !== 'event')) {
      alert('carousel-type can only distribute event to RS server content is already exist !');
      selectedTableRef.current.onAllSelected(false);
    } else if (addData.length < 1) {
      alert('Please select an event to distribute to the RS server');
      selectedTableRef.current.onAllSelected(false);
    } else {
      const deploy = addData.map(deployRow => deployRow.contentId);
      setDeployData(deploy);
      setIsBannerDeploy(true);
    }
  };

  const deleteEventConfirm = () => {
    if (addData.find(searchRow => searchRow.carouselType !== 'event')) {
      alert('carousel-type can only distribute event to RS server content is already exist !');
      selectedTableRef.current.onAllSelected(false);
    } else if (addData.length < 1) {
      alert('Select an event to delete');
      selectedTableRef.current.onAllSelected(false);
    } else {
      const deploy = addData.map(deployRow => deployRow.contentId);
      setDeployData(deploy);
      setIsBannerDelete(true);
    }
  };

  const publishEvent = () => {
    setIsBannerDeploy(false);
    fetch(`${API_BASE_URL}/carousel/event/publish`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(deployData),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          if (body.message) {
            alert(body.message);
          } else {
            alert('Some Error Occurred !');
          }
        } else {
          setAddData([]);
          alert('Successfully save !');
          searchCarouselData(carousel.carouselIdx, searchData);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };
  const deleteEvent = () => {
    setIsBannerDelete(false);
    fetch(`${API_BASE_URL}/carousel/event`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(deployData),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'ok') {
          setAddData([]);
          searchCarouselData(carousel.carouselIdx, searchData);
          alert('Successfully delete !');
        } else if (body.result === 'fail') {
          alert('You cannot delete organized events.');
        } else {
          alert('Some Error Occurred !');
        }
      })
      .catch(() => {})
      .finally(() => {});
  };
  return (
    <div>
      <React.Fragment>
        {/* <Display cat={cat}></Display> */}
        <Typography>
          {' '}
          Region:{' '}
          <Typography component="span" variant="h5">
            {carousel.region}
          </Typography>{' '}
          | Tab/Category:{' '}
          <Typography component="span" variant="h5">
            {carousel.tabName}/{carousel.categoryName}
          </Typography>{' '}
          | content Type:{' '}
          <Typography component="span" variant="h5">
            {carousel.contentType}
          </Typography>
        </Typography>
        <SearchFilter searchData={searchData} setSearchData={setSearchData} filterData={filterData} />
        <div className="btn_set_wrap search_wrap">
          <Button className="btn_color2" onClick={searchList}>
            search
          </Button>
          {isEventButton && (
            <Button className="btn_color2" onClick={createEvent}>
              CREATE EVENT
            </Button>
          )}
        </div>
        <div className="channel_mapping_wrap">
          {searchFetch ? (
            <div className="loading_wrap">
              <LoadingIndicator />
            </div>
          ) : (
            <CarouselSearch
              selectedTableRef={selectedTableRef}
              carouselType={searchCarouselType}
              contentType={searchContentType}
              searchResultData={searchResult}
              carouselResult={carouselResult}
              previewEvent={previewEvent}
              publishEvent={publishEventConfirm}
              deleteEvent={deleteEventConfirm}
              searchResultCheckedRow={searchResultCheckedRow}
              isResultRow={isResultRow}
            />
          )}
          {resultFetch ? (
            <div className="loading_wrap">
              <LoadingIndicator />
            </div>
          ) : (
            <CarouselResult
              resultTableRef={resultTableRef}
              carouselIdx={carousel.carouselIdx}
              countryCode={searchData.countryCode}
              carouselResult={carouselResult}
              setCarouselResult={setCarouselResult}
              carouselResultCheckedRow={carouselResultCheckedRow}
              getCarouselResult={getCarouselResult}
              getCarouselResetResult={getCarouselResetResult}
              onClickAdd={addResult}
              onClickDelete={removeResult}
              resultView={resultView}
              hubTabView={hubTabViewCheck}
            />
          )}
        </div>
        <Dialog open={popupType.add || popupType.preview} className={'pop_wrap'}>
          {popupType.add && <EventForm categoryIdx={carousel.categoryIdx} deviceType={carousel.deviceType} country={searchData.countryCode} handleClose={closedEvent} searchList={searchList} filterData={filterData} />}
          {popupType.preview && <EventForm categoryIdx={carousel.categoryIdx} deviceType={carousel.deviceType} country={searchData.countryCode} eventBanner={eventBanner} handleClose={closedEvent} searchList={searchList} filterData={filterData} />}
        </Dialog>
        <Dialog open={uploadPopupType.add || uploadPopupType.preview} className={'pop_wrap'}>
          {uploadPopupType.preview && <ResultViewForm resultView={resultViewData} tabCategory={`${carousel.tabName}/${carousel.categoryName}`} handleClose={closedEvent} carouselResult={carouselResult} setCarouselResult={setCarouselResult} resetResultCheckBox={resetResultCheckBox} />}
        </Dialog>
        <Dialog open={descPopupType.add || descPopupType.preview} className={'pop_wrap'}>
          {descPopupType.preview && <ResultTextViewForm resultView={resultViewData} tabCategory={`${carousel.tabName}/${carousel.categoryName}`} handleClose={closedEvent} carouselResult={carouselResult} setCarouselResult={setCarouselResult} resetResultCheckBox={resetResultCheckBox} />}
        </Dialog>
        <CommonDialog
          open={isBannerDeploy}
          description="Do you want to deploy to the RS server?"
          handleCancel={() => {
            setIsBannerDeploy(false);
          }}
          handleOk={() => {
            publishEvent();
          }}
        />
        <CommonDialog
          open={isBannerDelete}
          description="Are you sure you want to delete the event?"
          handleCancel={() => {
            setIsBannerDelete(false);
          }}
          handleOk={() => {
            deleteEvent();
          }}
        />
      </React.Fragment>
    </div>
  );
}

Carousel.propTypes = {
  id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Carousel));
