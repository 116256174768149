import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
import { showLoader, hideLoader } from './loaderActions';

export const getTermsAction = () => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/termslist?type=termsOfUse`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_TERMS_DATA', payload: body.termsList }))
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const addTermsAction = data => dispatch => {
  const term = { ...data };
  term['countryCode'] = term.countryCode.join(','); // array to string

  if (term['deviceType'] === 'pc') {
    term['deviceVersion'] = '';
    term['devicePlatform'] = 'pc_platform';
  } else {
    if (term['devicePlatform'] === 'ALL') {
      term['devicePlatform'] = 'Android,IOS';
    }
  }
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(term),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getTermsAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateTermsAction = data => dispatch => {
  const term = { ...data };
  term['countryCode'] = term.countryCode.join(','); // array to string
  term['updateVersion'] = term.updateVersion ? term.updateVersion : ''; // array to string

  if (term['deviceType'] === 'pc') {
    term['deviceVersion'] = '';
    term['devicePlatform'] = 'pc_platform';
  } else {
    if (term['devicePlatform'] === 'ALL') {
      term['devicePlatform'] = 'Android,IOS';
    }
  }
  // ======= Api Call =======
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(term),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getTermsAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getHisttoryAction = (type, id) => dispatch => {
  return fetch(`${API_BASE_URL}/admin/termshistory/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'TERMS_PRIVACY_HISTORY_DATA', payload: body.termsList }))
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getPrivacyPoliciesAction = () => dispatch => {
  //======= Api Call =======
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/termslist?type=privacyPolicy`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_PRIVACY_POLICIES_DATA', payload: body.termsList }))
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const addPrivacyPolicyAction = data => dispatch => {
  const policy = { ...data };
  policy['countryCode'] = policy.countryCode.join(','); // array to string

  if (policy['deviceType'] === 'pc') {
    policy['deviceVersion'] = '';
    policy['devicePlatform'] = 'pc_platform';
  } else {
    if (policy['devicePlatform'] === 'ALL') {
      policy['devicePlatform'] = 'Android,IOS';
    }
  }
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(policy),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getPrivacyPoliciesAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updatePrivacyPolicyAction = data => dispatch => {
  const policy = { ...data };
  policy['countryCode'] = policy.countryCode.join(','); // array to string
  policy['updateVersion'] = policy.updateVersion ? policy.updateVersion : ''; // array to string

  if (policy['deviceType'] === 'pc') {
    policy['deviceVersion'] = '';
    policy['devicePlatform'] = 'pc_platform';
  } else {
    if (policy['devicePlatform'] === 'ALL') {
      policy['devicePlatform'] = 'Android,IOS';
    }
  }

  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(policy),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getPrivacyPoliciesAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const publishTermsAction = data => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms/published-terms?type=${data}`, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) alert('Successfully Published !');
      else alert('Some Error Occured !');
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};
export const termsPublishAction = id => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/terms/${id}/published`, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) alert('Successfully Published !');
      else alert('Some Error Occurred !');
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const policyPublishAction = id => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/policy/${id}/published`, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) alert('Successfully Published !');
      else alert('Some Error Occurred !');
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getSupportLanguagesAction = () => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/supportLangList`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_SUPPORT_LANGUAGES_DATA', payload: body });
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};
