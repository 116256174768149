import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const UpcomingChannelList = (props) => {
  const showTableRef = useRef();
  const { searchChannelResult, callbackSelectedChannel } = props;
  const [changeChannelList, setChangeChannelList] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState('');

  useEffect(() => {
    setChangeChannelList([...searchChannelResult]);
  }, [searchChannelResult]);

  const handleAction = (item) => {
    if (item) {
      setSelectedChannelId(item.channelId);
      callbackSelectedChannel(item.channelId);
    }
  };

  return (
    <React.Fragment>
        <div className='live_tv_channel_list'>
          <MaterialTable
            title=""
            tableRef={showTableRef}
            columns={[
              {
                title: 'Logo',
                field: 'logoUrl',
                align: 'center',
                width: '10%',
                render: rowData => <img src={rowData.logoUrl} style={{ width: 70, backgroundColor: 'black' }} />,
              },
              {
                title: 'Ch. number',
                field: 'majorChannelNumber',
                align: 'center',
                width: '18%',
              },
              {
                title: 'Ch. name',
                field: 'channelName',
                align: 'center',
                width: '18%',
              },
              {
                title: 'Ch. ID',
                field: 'channelId',
                align: 'center',
                width: '18%',
              },
              {
                title: 'CP Name',
                field: 'providerId',
                align: 'center',
                width: '10%',
              },
              {
                title: 'Action',
                field: '',
                align: 'center',
                width: '10%',
                render: rowData => (
                  rowData.relatedRule === 'cp' || rowData.relatedRule === 'block' ? (
                    <></>
                  ) : (
                    <Button
                      disabled={rowData.relatedRule === 'auto' ? true : false}
                      className="btn_size_tbl btn_color3"
                      variant="contained"
                      onClick={() => handleAction(rowData)}
                    >
                      Organize
                    </Button>
                  )
                ),
              },
            ]}
            data={changeChannelList.map(item => ({
              ...item,
              tableData: {
                ...item.tableData,
                checked: item.relatedFlag === 'on',
              },
            }))}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50],
              sorting: true,
              selection: false,
              maxBodyHeight: 510,
              rowStyle: rowData => ({})
            }}
          />
        </div>
    </React.Fragment>
  );
}

export default UpcomingChannelList;