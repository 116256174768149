import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, Typography } from '@material-ui/core';
import UpcomingChannelList from './UpcomingChannelList';
import UpcomingSearch from './UpcomingSearch';
import UpcomingResult from './UpcomingResult';
import UpcomingSearchFilter from './UpcomingSearchFilter';


function Upcoming({ id }) {
  const selectedTableRef = useRef();
  const resultTableRef = useRef();
  const [upcoming, setUpcoming] = useState({});
  const defaultSearchData = {
    countryCode: '',
    contentType: '',
    provider: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const [searchChannelResult, setSearchChannelResult] = useState([]);
  const [searchProgramResult, setSearchProgramResult] = useState([]);
  const [upcomingResult, setUpcomingResult] = useState([]);
  const [currentChannelId, setCurrentChannelId] = useState('');

  const [addData, setAddData] = React.useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const [searchFetch, setSearchFetch] = useState(false);
  const [resultFetch, setResultFetch] = useState(false);

  const defaultSearchFilterData = {
    countryList: [],
    contentTypeList: [],
  };
  const [filterData, setFilterData] = useState(defaultSearchFilterData);

  useEffect(() => {
    fetch(`${API_BASE_URL}/category/${id}/upcoming`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setUpcoming(body.info);
          const contentType = body.info.contentType.split(',');
          setFilterData({ countryList: body.info.countryList, contentTypeList:contentType });
          setSearchData({ ...searchData, ...{ countryCode: body.info.countryList[0].countryCode, contentType:contentType[0], provider: '' } });
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      });
  }, [ id ]);

  useEffect(() => {
    if (upcoming.categoryIdx && searchData.countryCode !== ''){
      //getUpcomingResult(upcoming.categoryIdx, searchData.countryCode);
      setSearchChannelResult([]);
      setSearchProgramResult([]);
    }
  }, [ searchData.contentType ]);

  useEffect(() => {
    if (upcoming.categoryIdx && searchData.countryCode !== ''){
      getUpcomingResult(upcoming.categoryIdx, searchData.countryCode);
      setSearchChannelResult([]);
      setSearchProgramResult([]);
    }
  }, [ searchData.countryCode ]);

  const searchList = () => {
    searchChannelData(upcoming.categoryIdx, searchData);
  };

  const searchChannelData = (categoryIdx, search) => {
    if (search.contentType === 'live') {
      fetch(`${API_BASE_URL}/upcoming/${categoryIdx}/country/${search.countryCode}/channel?contentType=${search.contentType}&provider=${search.provider}`, {
        method: 'GET',
        headers: getHeaders(),
      })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setSearchChannelResult(body);
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      }).finally(() => {
        setSearchFetch(false);
      });
    } else {
      fetch(`${API_BASE_URL}/upcoming/${categoryIdx}/country/${search.countryCode}/vod?contentType=${search.contentType}&provider=${search.provider}`, {
        method: 'GET',
        headers: getHeaders(),
      })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setSearchProgramResult(body);
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      }).finally(() => {
        setSearchFetch(false);
      });
    }
  };

  const handleSelectedChannel = (channelId) => {
    setCurrentChannelId(channelId);
    const param = `country=${searchData.countryCode}&channelId=${channelId}`;

    fetch(`${API_BASE_URL}/upcoming/program?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          alert('[server error] ' + body.message);
        } else {
          setSearchProgramResult(body);
        }
      })
      .catch(error => {
      }).finally(() => {
        setSearchFetch(false);
      });
  };

  const getUpcomingResult = (categoryIdx, countryCode) => {
    setResultFetch(true);
    fetch(`${API_BASE_URL}/upcoming/${categoryIdx}/country/${countryCode}/result`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert('[server error] ' + body.message);
      } else {
        setUpcomingResult(body.list);
      }
    })
    .catch(error => {
      alert('[error message] ' + error);
    }).finally(() => {
      setResultFetch(false);
    });
  };

  const isResultRow = (searchRow, resultRow) => {
    if (searchRow.contentType === 'program' ){
      return (searchRow.contentId === resultRow.contentId
        && searchRow.startDateTime === resultRow.startDateTime
        && searchRow.endDateTime === resultRow.endDateTime);
    } else {
      return (searchRow.contentId === resultRow.contentId);
    }
  };

  const searchResultChcheckedRow = rows => {
    if (rows.find(searchRow => upcomingResult.find(resultRow => isResultRow(searchRow, resultRow) ))) {
      //alert('content is already exist !');
      //selectedTableRef.current.onAllSelected(false);
    } else {
      let addOrderNumber = upcomingResult.length + 1;
      setAddData(
        rows.map((n, i) => {
          const { parentContentId, contentId, contentName, contentType, countryCode, imageUrl, providerId, providerIdx
                 , startDateTime, endDateTime, scheduleOrder } = n;
          return { parentContentId: parentContentId, contentId: contentId, contentName: contentName, contentType: contentType, countryCode: countryCode
                  , imageUrl: imageUrl, providerId: providerId, providerIdx: providerIdx
                  , startDateTime: startDateTime, endDateTime: endDateTime
                  , scheduleOrder: scheduleOrder, resultOrder: addOrderNumber + i };
        }),
      );
    }
  };

  const upcomingResultChcheckedRow = rows => {
    setDeleteData(rows);
  };

  const addResult = () => {
    if (addData.find(searchRow => upcomingResult.find(resultRow => isResultRow(searchRow, resultRow) ))) {
      alert('content is already exist !');
      selectedTableRef.current.onAllSelected(false);
    } else {
      selectedTableRef.current.onAllSelected(false);
      setUpcomingResult(upcomingResult.concat(addData));
      setAddData([]);
    }
  };

  const removeResult = () => {
    const copyResult = upcomingResult.filter(resultRow => !deleteData.find(deleteRow => deleteRow.contentId === resultRow.contentId
                                                                                     && deleteRow.startDateTime === resultRow.startDateTime
                                                                                     && deleteRow.endDateTime === resultRow.endDateTime));
    setUpcomingResult(
      copyResult.map((n, i) => {
        const { parentContentId, contentId, contentName, contentType, countryCode, imageUrl, providerId, providerIdx
                , startDateTime, endDateTime, scheduleOrder  } = n;
        return { parentContentId: parentContentId, contentId: contentId, contentName: contentName, contentType: contentType, countryCode: countryCode
                 , imageUrl: imageUrl, providerId: providerId, providerIdx: providerIdx
                 , startDateTime: startDateTime, endDateTime: endDateTime
                 , scheduleOrder: scheduleOrder, resultOrder: 1 + i };
      }),
    );
    setDeleteData([]);
  };

  return (
    <div>
      <React.Fragment>
        {/* <Display cat={cat}></Display> */}
        <Typography>
          {' '}
          Region:{' '}
          <Typography component="span" variant="h5">
            {upcoming.region}
          </Typography>{' '}
          | Tab/Category:{' '}
          <Typography component="span" variant="h5">
            {upcoming.tabName}/{upcoming.categoryName}
          </Typography>{' '}
          | content Type:{' '}
          <Typography component="span" variant="h5">
          {upcoming.contentType}
          </Typography>
          | category Type:{' '}
          <Typography component="span" variant="h5">
          {upcoming.categoryType}
          </Typography>
        </Typography>
        <UpcomingSearchFilter
          searchData={searchData}
          setSearchData={setSearchData}
          filterData={filterData}
        />
        <div className="btn_set_wrap search_wrap">
        <Button className="btn_color2" onClick={searchList}>
          search
        </Button>
        </div>
        {searchData.contentType === 'live' ?
          (
            <UpcomingChannelList
              searchChannelResult={searchChannelResult}
              callbackSelectedChannel={handleSelectedChannel}
            />
          ) :
          (
            <div></div>
          )
        }
        <div className="channel_mapping_wrap">
          {searchFetch ?
            (
              <div className="loading_wrap">
                <LoadingIndicator />
              </div>
            ) :
            (
              <UpcomingSearch
                selectedTableRef={selectedTableRef}
                searchResultData={searchProgramResult}
                upcomingResult={upcomingResult}
                searchResultChcheckedRow={searchResultChcheckedRow}
                isResultRow={isResultRow}
              />
            )
          }
          {resultFetch ?
            (
              <div className="loading_wrap">
                <LoadingIndicator />
              </div>
            ) :
            (
              <UpcomingResult
                resultTableRef={resultTableRef}
                categoryIdx={upcoming.categoryIdx}
                countryCode={searchData.countryCode}
                upcomingResult={upcomingResult}
                setUpcomingResult={setUpcomingResult}
                upcomingResultChcheckedRow={upcomingResultChcheckedRow}
                getUpcomingResult={getUpcomingResult}
                onClickAdd={addResult}
                onClickDelete={removeResult}
              />
            )
          }
        </div>
      </React.Fragment>
    </div>
  );
}

Upcoming.propTypes = {
  id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Upcoming));



