/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';
//import { request } from '../../../../util/request';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommonDialog from '../../../common/dialog/CommonDialog';
import { Button, NativeSelect } from '@material-ui/core';
import MaterialTable, { MTableBodyRow } from 'material-table';

function UpcomingResult({ resultTableRef, history, categoryIdx, countryCode
                          , upcomingResult, setUpcomingResult, upcomingResultChcheckedRow
                          , getUpcomingResult, onClickAdd, onClickDelete }) {
  const [isSaved, setIsSaved] = useState(false);

  const clickSave = async e => {
    e.preventDefault();
    if (upcomingResult.length < 1){
      alert('The number of result is less than 1 ! Please add at lease one content !');
    } else if (upcomingResult.find(n => n.result_order === '')){
      alert('Some order is empty ! Please enter the order !');
    /*
    } else if (upcomingResult.length > 100){
      alert('Cannot exceed 100 content.');
    */
    } else {
      setIsSaved(true);
    }
  };

  const saveUpcomingResult = ()  => {
    const upcomingResesultList = upcomingResult.map(n => {
      const { countryCode, providerIdx, parentContentId, contentId, contentName, contentType, resultOrder, scheduleOrder, startDateTime, endDateTime } = n;
      let result = { categoryIdx : categoryIdx, parentContentId : parentContentId
                    , countryCode : countryCode, providerIdx : providerIdx , contentId : contentId
                    , contentName : contentName, contentType : contentType
                    , resultOrder : resultOrder, scheduleOrder: scheduleOrder
                    , startDateTime: startDateTime, endDateTime: endDateTime };
      return result;
    });

    fetch(`${API_BASE_URL}/upcoming/${categoryIdx}/country/${countryCode}/result`, {
      method: 'PUT',
      headers: getHeaders(),
      body : JSON.stringify(upcomingResesultList)
    })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        if (body.message){
          alert(body.message);
        } else {
          alert('Some Error Occured !');
        }
      } else {
        alert('Successfully save !');
        getUpcomingResult(categoryIdx, countryCode);
      }
    })
    .catch(() => {})
    .finally(() => {
      setIsSaved(false);
    });
  };

  const [order, setOrder] = React.useState({ key: "", type: "" });
  const DrageState = {
    row: -1,
    dropIndex: -1, // drag target
    position: -1,
    dragPosition: -1,
    type: '',
    srtart: -1,
    end: -1,
  };

  //Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to, type) => {
    let newtableData = [];
    if (type === 'Swap') {
      [upcomingResult[from], upcomingResult[to]] = [upcomingResult[to], upcomingResult[from]];
      let start = upcomingResult[0],
        between = upcomingResult.slice(1, upcomingResult.length - 1),
        end = upcomingResult[upcomingResult.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      newtableData = offsetIndex(from, to, upcomingResult);
    } else {
      return;
    }
    newtableData.map((element, index) => (element.resultOrder = index + 1));
    setUpcomingResult(newtableData);
  };

  const column = [
    { title: 'parentContentId', field: 'parentContentId', hidden: true },
    { title: 'countryCode', field: 'countryCode', hidden: true },
    { title: 'providerIdx', field: 'providerIdx', hidden: true },
    { title: 'scheduleOrder', field: 'scheduleOrder', hidden: true },
    { title: 'order', field: 'resultOrder', minWidth:30, mixWidth:30 },
    { title: 'type', field: 'contentType', minWidth:40 },
    { title: 'cp', field: 'providerId' },
    { title: 'id', field: 'contentId', minWidth:70 },
    { title: 'name', field: 'contentName', minWidth:70 },
    { title: 'image', render: n => <img src={n.imageUrl} style={{ width: 70, backgroundColor: 'black' }} />},
    { title: 'startDate', field: 'startDateTime', minWidth: 60, mixWidth:60},
    { title: 'endDate', field: 'endDateTime', minWidth: 60, mixWidth:60},
  ];

  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span id="popup"></span>
        <MaterialTable
          tableRef={resultTableRef}
          resultTableRef
          title=""
          columns={column}
          onOrderChange={(key, type) => {
            setOrder({ key: `${key}`, type });
          }
        }
          components={{
            Row: props => (
              <MTableBodyRow
                {...props}
                draggable="true"
                onDragStart={e => {
                  DrageState.row = props.data.tableData.id;
                  DrageState.srtart = e.target.getBoundingClientRect().top;
                }}
                onDragEnter={e => {
                  e.preventDefault();
                  if (props.data.tableData.id !== DrageState.row) {
                    DrageState.dropIndex = props.data.tableData.id;
                    DrageState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                    document.getElementById('popup').style.display = 'block';
                  }
                  DrageState.end = e.target.parentElement.getBoundingClientRect().top;
                  if (DrageState.srtart == DrageState.end) {
                    document.getElementById('popup').style.display = 'none';
                  }
                }}
                onDragOver={e => {
                  DrageState.dragPosition = e.clientY;
                  document.getElementById('popup').clientY = e.clientY;
                  document.getElementById('popup').innerHTML = 'Insert';
                  DrageState.type = document.getElementById('popup').innerHTML;
                }}
                onDragEnd={e => {
                  if (DrageState.dropIndex !== -1 && DrageState.srtart != DrageState.end) {
                    reOrderRow(DrageState.row, DrageState.dropIndex, DrageState.type);
                  }
                  DrageState.row = -1;
                  DrageState.dropIndex = -1;
                  DrageState.position = -1;
                  DrageState.dragPosition = -1;
                  DrageState.type = '';
                  DrageState.srtart = -1;
                  DrageState.end = -1;
                  document.getElementById('popup').style.display = 'none';
                }}
              />
            ),
          }}
          data={upcomingResult}
          options={{
            search: false,
            selection: true,
            showTextRowsSelected: false,
            isLoading: true,
            paging: false,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 800,
            minBodyHeight: 800,
            //showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
          onSelectionChange={upcomingResultChcheckedRow}
        />
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button className="btn_color2" onClick={clickSave}>
                save
              </Button>
              <Button
                className="btn_color1"
                onClick={() => {
                  history.push('/HOME-APP/tab');
                }}>
                Back
              </Button>
            </div>
          </form>
        </React.Fragment>
      </div>
      <CommonDialog
        open={isSaved}
        description="Do you want to save the content?"
        handleCancel={() => {
          setIsSaved(false);
        }}
        handleOk={() => {
          saveUpcomingResult();
        }}
      />
    </React.Fragment>
  );
}
UpcomingResult.propTypes = {
  countryCode: PropTypes.string.isRequired,
  currentChannelId: PropTypes.string.isRequired,
  upcomingResult: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpcomingResult));
