//import React from 'react';
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Button, Switch } from '@material-ui/core';

export default function CarouselSearch({ selectedTableRef, searchResultData, carouselType, contentType, carouselResult, searchResultCheckedRow, previewEvent, publishEvent, deleteEvent, isResultRow }) {
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  const getEventRow = eventRow => {
    previewEvent(eventRow.contentId, eventRow.contentTitle, eventRow.image, eventRow.verticalImage, eventRow.focusImage, eventRow.deepLink, eventRow.linkContentType, eventRow.linkContentId);
  };

  const getImage = image => {
    if (image === null || image === '') {
      return '';
    } else if (image.indexOf('http') > -1) {
      return <img alt="invalid" src={image} title={image} style={{ backgroundColor: 'black', maxWidth: 100, maxHeight: 100, border: '1px solid #cbccce' }} />;
    } else {
      return <img alt="invalid" src={prefix + image} title={image} style={{ backgroundColor: 'black', maxWidth: 100, maxHeight: 100, border: '1px solid #cbccce' }} />;
    }
  };

  const column = [
    { title: 'carouselIdx', field: 'carouselIdx', hidden: true },
    { title: 'carouselId', field: 'carouselId', hidden: true },
    { title: 'countryCode', field: 'countryCode', hidden: true },
    { title: 'type', field: 'contentType', hidden: carouselType === 'channel' || carouselType === 'vod' || carouselType === 'hub' ? false : true },
    { title: 'id', field: 'contentId' },
    { title: 'name', field: 'contentName', hidden: carouselType === 'event' ? true : false },
    {
      title: 'title',
      hidden: carouselType === 'event' ? false : true,
      render: rowData => (
        <button
          onClick={() => {
            getEventRow(rowData);
          }}>
          <span className="link">{rowData.contentTitle}</span>
        </button>
      ),
    },
    { title: 'Publish', hidden: carouselType === 'event' ? false : true, render: row => <Switch checked={row.eventPublishedFlag === 'Y'} disabled={true} inputProps={{ 'aria-label': 'secondary checkbox' }} /> },
    { title: 'CP', field: 'providerId', hidden: carouselType === 'channel' || carouselType === 'vod' ? false : true },
    { title: 'providerIdx', field: 'providerIdx', hidden: true },
    { title: carouselType === 'event' ? 'horizontal Image' : 'image', hidden: carouselType === 'channel' || carouselType === 'vod' || carouselType === 'event' ? false : true, render: n => getImage(n.image) },
    { title: 'vertical image', hidden: carouselType === 'event' ? false : true, render: n => getImage(n.verticalImage) },
    { title: 'focus Image', hidden: carouselType === 'event' ? false : true, render: n => getImage(n.focusImage) },
    { title: 'link', field: 'channelMap', hidden: carouselType === 'event' ? false : true, filtering: false, render: row => (row.deepLink && row.deepLink === 'Y' ? <span>Y({row.linkContentId})</span> : <span>N</span>) },
    { title: 'genre', field: 'genre', hidden: true },
    { title: 'manualFlag', field: 'manualFlag', hidden: true },
    { title: 'tabCode', field: 'tabCode', hidden: true },
    { title: 'tab', field: 'tabName', hidden: carouselType === 'more' || (carouselType === 'hub' && contentType === 'category') ? false : true },
    { title: 'category type', field: 'categoryType', hidden: carouselType === 'more' || (carouselType === 'hub' && contentType === 'category') ? false : true },
    { title: 'start', field: 'startDate', hidden: carouselType === 'notification' ? false : true },
    { title: 'end', field: 'endDate', hidden: carouselType === 'notification' ? false : true },
    { title: 'map', field: 'channelMap', hidden: carouselType === 'channel' || carouselType === 'notification' ? false : true, filtering: false, render: row => (row.channelMap ? row.channelMap.split(',').map((n, index) => <div key={index}>{n}</div>) : '') },
  ];

  const handlePublish = () => {
    publishEvent();
  };
  const handleDelete = () => {
    deleteEvent();
  };

  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'}>
      <MaterialTable
        title=""
        columns={column}
        tableRef={selectedTableRef}
        data={searchResultData}
        options={{
          selection: true,
          showTextRowsSelected: false,
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 800,
          minBodyHeight: 800,
          rowStyle: rowData => ({
            backgroundColor: carouselResult.find(resultRow => isResultRow(rowData, resultRow)) ? '#e0e0e0' : rowData.tableData.checked ? '#fef5f8' : '#FFF',
            color: rowData.tableData.checked ? '#d8295c' : '#484848',
            pointerEvents: !rowData.tableData.checked && carouselResult.find(resultRow => isResultRow(rowData, resultRow)) ? 'none' : 'initial',
          }),
          selectionProps: rowData => {
            const findCurrentRow = carouselResult.find(resultRow => isResultRow(rowData, resultRow));
            rowData.tableData.disabled = findCurrentRow ? true : false;
            return {
              disabled: findCurrentRow ? true : false,
            };
          },
        }}
        onSelectionChange={searchResultCheckedRow}
      />
      {carouselType === 'event' && (
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button
                className="btn_color2"
                onClick={() => {
                  handleDelete();
                }}>
                DELETE EVENT
              </Button>
              <Button
                className="btn_color2"
                onClick={() => {
                  handlePublish();
                }}>
                DEPLOY EVENT
              </Button>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  );
}

CarouselSearch.propTypes = {
  publishEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  previewEvent: PropTypes.func.isRequired,
  isResultRow: PropTypes.func.isRequired,
  searchResultCheckedRow: PropTypes.func.isRequired,
  searchResultData: PropTypes.array.isRequired,
  carouselResult: PropTypes.array.isRequired,
  carouselType: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
