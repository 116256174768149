import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';
import TermsPolicyView from './TermsPolicyView';

function TermsPolicyHistory({ title, closeHandler, policyId, getTermsPolicy }) {
  const [open, setOpen] = useState(false);
  const [termsPolicyHisList, setTermsPolicyHisList] = useState();
  const [termsPolicyHis, setTermsPolicyHis] = useState();

  useEffect(() => {
    getTermsPolicyHistoryList(policyId)
  }, [policyId]);

  const getTermsPolicyHistoryList = (policyId) => {
    fetch(`${API_BASE_URL}/admin/terms-policy/${policyId}/history`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setTermsPolicyHisList(body.list)
      } else {
        alert('[server error] ' + body.message);
      }
    })
    .catch(error => {
      console.log("error: " , error);
    }).finally(() => {});
  };

  const termsPolicyViewHandler = (policyIdx) => {
    getTermsPolicy(policyIdx, viewTermsPolicyHistory);
  };

  const viewTermsPolicyHistory = (termsPolicyHistoryDetail) => {
    if(termsPolicyHistoryDetail && termsPolicyHistoryDetail.idx > 0){
      setTermsPolicyHis(termsPolicyHistoryDetail);
      setOpen(true);
    }
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {`History - ${title}`}
        <IconButton aria-label="close" onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <div className="tbl_wrap no_sch mt20 no_round">
          <MaterialTable
            title="Version History"
            columns={[
              {
                title: 'Version',
                field: 'policyVersion',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    <span className="link">{rowData.policyVersion}</span>
                  </button>
                ),
              },
              {
                title: 'Country',
                field: 'countries',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    <span className="link">{rowData.countries}</span>
                  </button>
                ),
              },
              {
                title: 'Content Size',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    <span className="link">
                      {rowData.contentSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </button>
                ),
              },
              {
                title: 'action',
                field: 'userAction',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    {rowData.userAction}
                  </button>
                )
              },
              {
                title: 'published',
                field: 'publishedFlag',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    {rowData.publishedVersion ? rowData.publishedFlag + ' (Released: ' + rowData.publishedVersion + ')' : rowData.publishedFlag}
                  </button>
                )
              },
              {
                title: 'Change Id',
                field: 'lastChgUsrId',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    {rowData.lastChgUsrId}
                  </button>
                ),
              },
              {
                title: 'Change Date',
                field: 'crtDate',
                render: rowData => (
                  <button type="button" onClick={() => termsPolicyViewHandler(rowData.idx)}>
                    {rowData.crtDate}
                  </button>
                ),
              },
            ]}
            data={termsPolicyHisList}
            options={{
              search: false,
              paging: true,
            }}
          />
        </div>
        <div className={'btn_set_wrap alC'}>
          <Button variant="contained" className={'btn_color2'} onClick={closeHandler}>
            Close
          </Button>
        </div>
      </DialogContent>
      <Dialog open={open} className={'pop_wrap'}>
        <TermsPolicyView termsPolicy={termsPolicyHis} title={title} closeHandler={() => setOpen(false)} />
      </Dialog>
    </Fragment>
  );
};

TermsPolicyHistory.propTypes = {
  title: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  getTermsPolicy: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TermsPolicyHistory);
