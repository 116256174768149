import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Button, DialogContent, DialogTitle, makeStyles, Switch, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  termsPolicyDispaly: { fontWeight: 'normal' },
  contentText: { verticalAlign: 'baseline' }
}));

function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
};

function TermsPolicyView({ title, closeHandler, termsPolicy }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('#000');
  const [preview, setPreview] = useState(false);

  const tabChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const backgroundColorChangeHandler = () => {
    if (backgroundColor === '#FFF'){
      setBackgroundColor('#000');
    } else {
      setBackgroundColor('#FFF');
    }
  };

  return (
    <Fragment>
      {(title && termsPolicy) && (
        <Fragment>
          <DialogTitle className="pop_head" id="form-dialog-title">
            {`View ${title}`}
            <IconButton aria-label="close" onClick={closeHandler}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <div>
            <TableContainer className={'tbl_wrap'}>
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell width="15%">Device Type</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.deviceType}
                    </TableCell>
                    <TableCell width="15%">Device Platform</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.devicePlatformCodeList.join(', ')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Comment</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.policyComment}
                    </TableCell>
                    <TableCell width="15%">Version</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                    {termsPolicy.policyVersion}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Enable</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.enableFlag}
                    </TableCell>
                    <TableCell width="15%">Published</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {(termsPolicy.publishedVersion) ? termsPolicy.publishedFlag + ' (Released: ' + termsPolicy.publishedVersion + ')' : termsPolicy.publishedFlag}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Display Option</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                        {termsPolicy.displayOption}
                    </TableCell>
                    <TableCell width="15%">Display Order</TableCell>
                    <TableCell width="35%" colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.displayOrder}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Country</TableCell>
                    <TableCell width="35%"  colSpan={2} className={classes.termsPolicyDispaly}>
                      {termsPolicy.countryCodeList.join(', ') }
                    </TableCell>
                    <TableCell width="15%">Preview</TableCell>
                    <TableCell width="20%" colSpan={2}  className={classes.termsPolicyDispaly}>
                      <Switch checked={preview} inputProps={{ 'aria-label': 'secondary checkbox' }} onClick={() => setPreview(!preview)}/>
                      {(preview) && (
                        <Fragment>
                          {'  '}<Button variant="contained" className={'btn_color2 btn_size_tbl'} onClick={() => backgroundColorChangeHandler()}>
                            Color <font color={backgroundColor}> : {backgroundColor} </font>
                        </Button>
                       </Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%" className={classes.contentText}>Content</TableCell>
                    <TableCell colSpan={5} className={classes.termsPolicyDispaly}>
                      {(termsPolicy.contentList && termsPolicy.contentList.length > 0 ) && (
                        <Fragment>
                          <AppBar position="static" id="scheduleTabHead" style={{maxWidth : '790px'}}>
                            <Tabs value={tabValue} onChange={tabChangeHandler} aria-label="simple tabs example">
                              {termsPolicy.contentList.map((content, i) => (
                                <Tab key={content.languageCode} label={content.languageCode} {...a11yProps(i)} />
                              ))}
                            </Tabs>
                          </AppBar>
                          {termsPolicy.contentList.map((content, index) => (
                            <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                              <div>
                                <b>Title:</b>
                                {preview ? (
                                  <div dangerouslySetInnerHTML={{ __html: content.policyTitle }} style={{ whiteSpace: 'pre-line', backgroundColor: '#FFF', maxWidth : '790px' }} />
                                ) : (
                                  <div>{content.policyTitle}</div>
                                )}

                              </div>
                              <b>Description:</b>
                              {preview ? (
                                <div dangerouslySetInnerHTML={{ __html: content.policyDescription }} style={{ whiteSpace: 'pre-line', backgroundColor: backgroundColor, maxWidth : '790px' }} />
                              ) : (
                                <div style={{ whiteSpace: 'pre-wrap', maxWidth : '790px' }}>{content.policyDescription}</div>
                              )}
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <div className={'btn_set_wrap alC'}>
              <Button variant="contained" className={'btn_color2'} onClick={closeHandler}>
                Close
              </Button>
            </div>
            </div>
          </DialogContent>
        </Fragment>
      )}
    </Fragment>
  );
};

TermsPolicyView.propTypes = {
  title: PropTypes.string.isRequired,
  termsPolicy: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default connect()(TermsPolicyView);
