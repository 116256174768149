import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../../constants';
import { getHeadersFormData } from '../../../../util/actionUtil';
import CommonDialog from '../../../../common/dialog/CommonDialog';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function CarouselResultTextViewForm({ resultView, tabCategory, handleClose, carouselResult, setCarouselResult, resetResultCheckBox }) {
  const [isSave, setIsSave] = useState(false);
  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');
  const [carouselType, setCarouselType] = useState('');
  const [contentId, setContentId] = useState('');
  const [contentName, setContentName] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [country, setCountry] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    if (resultView) {
      setCountry(resultView.countryCode);
      setCarouselType(resultView.carouselType);
      setContentId(resultView.contentId);
      setContentName(resultView.contentName);
      setResultTitle(resultView.resultTitle);
      setResultDescription(resultView.resultDescription);
      if (resultView.carouselType === 'hub') {
        setIsRequired(true);
      }
    }
  }, [resultView]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsSave(true);
  };

  const saveCarouselResultText = () => {
    setIsSave(false);
    if (!resultView || !resultView.contentId) {
      alert('Please select a content!');
      return;
    }
    if (isRequired) {
      if (resultTitle == null || resultTitle == '') {
        alert('Please enter a title.');
        return;
      }
      if (resultDescription == null || resultDescription == '') {
        alert('Please enter additional description.');
        return;
      }
    }
    setIsDisabled(true);
    const updateList = [];
    carouselResult.map(element => {
      if (element.contentId === resultView.contentId && element.carouselType === resultView.carouselType) {
        element.resultTitle = resultTitle;
        element.resultDescription = resultDescription;
      }
      updateList.push(element);
    });
    setCarouselResult(updateList);
    setIsDisabled(false);
    resetResultCheckBox();
    handleClose();
  };

  const titleChangeHandler = e => {
    setResultTitle(e.target.value);
  };

  const descriptionChangeHandler = e => {
    setResultDescription(e.target.value);
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Content Text
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Tab/Category</label>
                    </TableCell>
                    <TableCell colSpan={2}>{tabCategory}</TableCell>
                    <TableCell>
                      <label className="">Country</label>
                    </TableCell>
                    <TableCell colSpan={2}>{country}</TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Content Id</label>
                    </TableCell>
                    <TableCell colSpan={2}>{contentId}</TableCell>
                    <TableCell>
                      <label className="">Content Name</label>
                    </TableCell>
                    <TableCell colSpan={2}>{contentName}</TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Carousel Type</label>
                    </TableCell>
                    <TableCell colSpan={5} className="normal">
                      {carouselType}
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className={isRequired ? 'is_required' : ''}>Title Text</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <TextField fullWidth inputProps={{ maxLength: 1024 }} name="resultTitle" required margin="dense" variant="outlined" value={resultTitle} onChange={event => titleChangeHandler(event)} placeholder="Title" />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className={isRequired ? 'is_required' : ''}>Description Text</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <TextField fullWidth inputProps={{ maxLength: 4000 }} name="resultDescription" required margin="dense" variant="outlined" value={resultDescription} onChange={event => descriptionChangeHandler(event)} placeholder="Description" />
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <CommonDialog
        open={isSave}
        description="Would you like to register a title and description for this content?"
        handleCancel={() => {
          setIsSave(false);
        }}
        handleOk={() => {
          saveCarouselResultText();
        }}
      />
    </Fragment>
  );
}

CarouselResultTextViewForm.propTypes = {
  resultView: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  carouselResult: PropTypes.array.isRequired,
  setCarouselResult: PropTypes.func.isRequired,
  resetResultCheckBox: PropTypes.func.isRequired,
};

export default connect()(CarouselResultTextViewForm);
