import React, { Fragment, useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Dialog, Switch } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import TermPolicyForm from './TermsPolicyForm';
import TermsPolicyMultipleForm from './TermsPolicyMultipleForm';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import AlertDialog from '../../../common/dialog/AlertDialog';
import TermsPolicyView from './TermsPolicyView';
import TermsPolicyHistory from './TermsPolicyHistory';

function TermsOfUse({}) {
  useEffect(() => {
    getTermsPolicyList();
    getLanguages();
    getRegionCountryByDevice();
  }, []);
  const [supportLanguages, setSupportLanguages] = useState({ distinctLanguage: [], languageByCountry: [] });
  const [regionCountryByDevice, setRegionCountryByDevice] = useState([]);
  const [ricCountry, setRicCountry] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [listFilter, setListFilter] = React.useState({ deviceType: 'All', region: 'All', country: 'All' });
  const [termsPolicyList, setTermsPolicyList] = React.useState([]);
  const [displayTermsPolicyList, setDisplayTermsPolicyList] = React.useState([]);
  const [termsPolicy, setTermsPolicy] = React.useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [isPublish, setIsPublish] = useState(false);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(true);
  const defaultPopupType = { add: false, edit: false, preview: false, history: false };
  const [popupType, setPopupType] = useState(defaultPopupType);
  const defaultTermsPolicy = {
    idx: -1,
    policyId: '',
    policyType: 'terms',
    policyVersion: '0.0.0',
    definedVersion: '0.0.0',
    policyMajorVersion: 0,
    policyMinorVersion: 0,
    policyPatchVersion: 0,
    deviceType: '',
    updateVersion: '',
    devicePlatform: '',
    policyComment: '',
    displayOption: '',
    displayOrder: 1,
    enableFlag: '',
    publishedFlag: '',
    releaseFlag: 'N',
    publishedVersion: '',
    countries: '',
    crtUsrId: '',
    crtDate: '',
    contentLanguageCodeList: [],
    countryCodeList: [],
    devicePlatformCodeList: [],
    contentList: [],
    countryList: [],
    devicePlatformList: [],
    multipleDisplay: [],
  };

  useEffect(() => {
    if (regionCountryByDevice && regionCountryByDevice.length > 0) {
      setDeviceTypes(regionCountryByDevice.map(device => device.deviceType));
      let countryFilter = regionCountryByDevice
        .map(m => m.regionList)
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        });
      countryFilter = countryFilter
        .map(m => m.countryList)
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
        .map(m => m.countryCode);
      countryFilter = [...new Set(countryFilter)];
      countryFilter.sort((a, b) => a.localeCompare(b));
      setCountries(countryFilter);
    }
  }, [regionCountryByDevice]);

  useEffect(() => {
    let countryFilter = [];
    if (listFilter.deviceType) {
      if (ricCountry && ricCountry.length > 0) {
        if (listFilter.deviceType === 'All') {
          countryFilter = regionCountryByDevice
            .map(m => m.regionList)
            .reduce(function (acc, cur) {
              return acc.concat(cur);
            });
          setDisplayTermsPolicyList(termsPolicyList);
        } else {
          countryFilter = regionCountryByDevice.find(device => device.deviceType === listFilter.deviceType).regionList;
          setDisplayTermsPolicyList(termsPolicyList.filter(f => f.deviceType === listFilter.deviceType));
        }
        countryFilter = countryFilter
          .map(m => m.countryList)
          .reduce(function (acc, cur) {
            return acc.concat(cur);
          })
          .map(m => m.countryCode);
        countryFilter = [...new Set(countryFilter)];
        countryFilter.sort((a, b) => a.localeCompare(b));
      }
      setCountries(countryFilter);
    }
  }, [listFilter.deviceType]);

  useEffect(() => {
    let list = [];
    if (listFilter.deviceType && listFilter.country) {
      if (regionCountryByDevice && regionCountryByDevice.length > 0) {
        if (listFilter.deviceType === 'All') {
          list = [...termsPolicyList];
        } else {
          list = termsPolicyList.filter(f => f.deviceType === listFilter.deviceType);
        }
        if (listFilter.country !== 'All') {
          list = list.filter(m => m.countries.split(',').includes(listFilter.country));
        }
        setDisplayTermsPolicyList(list);
      } else {
        setDisplayTermsPolicyList(termsPolicyList);
      }
    }
  }, [listFilter.country]);

  useEffect(() => {
    if (termsPolicyList && termsPolicyList.length > 0 && termsPolicyList.find(f => f.publishedFlag === 'N')) {
      setPublishButtonDisabled(false);
    } else {
      setPublishButtonDisabled(true);
    }
  }, [termsPolicyList]);

  const getRegionCountryByDevice = () => {
    fetch(`${API_BASE_URL}/common/device-region-country`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (body.region.regionCountryByDevice) {
            // except mobile
            setRegionCountryByDevice(body.region.regionCountryByDevice.filter(f => f.deviceType !== 'mobile'));
          } else {
            setRegionCountryByDevice(body.region.regionCountryByDevice);
          }
          setRicCountry(body.region.distinctCountry);
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {});
  };
  const getLanguages = () => {
    fetch(`${API_BASE_URL}/common/support-language`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setSupportLanguages(body.language);
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {});
  };

  const getTermsPolicy = (policyIdx, showLayer) => {
    fetch(`${API_BASE_URL}/admin/terms-policy/${policyIdx}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (showLayer) {
            showLayer(body.termsPolicy);
          }
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {});
  };

  const getTermsPolicyList = () => {
    fetch(`${API_BASE_URL}/admin/terms-policy?policyType=terms`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setTermsPolicyList(body.list);
          setDisplayTermsPolicyList(body.list);
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {});
  };

  const termsPolicySaveSuccess = saveMessage => {
    setAlertDescription(saveMessage);
    setDisplayWarningList([]);
    getTermsPolicyList();
    setIsAlert(true);
    setOpenForm(false);
  };

  const enablePatchTermsPolicy = (policyIdx, patch, getTermsPolicyList) => {
    fetch(`${API_BASE_URL}/admin/terms-policy/${policyIdx}/${patch}`, {
      method: 'PATCH',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (getTermsPolicyList) {
            getTermsPolicyList();
          }
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {});
  };

  const publishTermsPolicy = getTermsPolicyList => {
    fetch(`${API_BASE_URL}/admin/terms-policy/publish/terms`, {
      method: 'POST',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setAlertDescription(body.message);
          setDisplayWarningList([]);
          setIsAlert(true);
          if (getTermsPolicyList) {
            getTermsPolicyList();
          }
        } else if (body.result === 'INVALID') {
          setAlertDescription('Please check the policies before posting.');
          setDisplayWarningList(body.invalidList);
          setIsAlert(true);
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(() => {
        setIsPublish(false);
      });
  };

  const editTermsPolicy = termsPolicyDetail => {
    if (termsPolicyDetail && termsPolicyDetail.idx > 0) {
      let version = termsPolicyDetail.policyVersion.split('.');
      setTermsPolicy({ ...termsPolicyDetail, ...{ policyMajorVersion: version[0], policyMinorVersion: version[1], policyPatchVersion: version[2] } });
      setPopupType({ ...defaultPopupType, edit: true });
      setOpenForm(true);
    }
  };
  const viewTermsPolicy = termsPolicyDetail => {
    if (termsPolicyDetail && termsPolicyDetail.idx > 0) {
      setTermsPolicy({ ...termsPolicyDetail });
      setPopupType({ ...defaultPopupType, preview: true });
      setOpenView(true);
    }
  };

  const listFilterHandler = event => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'deviceType') {
      setListFilter({ ...listFilter, ...{ deviceType: value, country: 'All' } });
    } else if (name === 'country') {
      setListFilter({ ...listFilter, ...{ [name]: value } });
    }
  };

  const enableHandler = (event, policyIdx) => {
    event.preventDefault();
    let deviceType = '';
    let displayOption = '';
    let displayOrder = 0;
    let enableCountry = [];
    let duplicateNumberList = [];
    let enableTarget;
    if (event.target.checked) {
      enableTarget = displayTermsPolicyList.find(f => f.idx === policyIdx);
      deviceType = enableTarget.deviceType;
      displayOption = enableTarget.displayOption;
      displayOrder = enableTarget.displayOrder;
      let arr = enableTarget.countries.split(',');
      let checkList = displayTermsPolicyList.filter(f => f.idx !== policyIdx && f.deviceType === deviceType && f.enableFlag === 'Y');

      arr.forEach(element => {
        let enableList = checkList.filter(c => c.countries.includes(element));
        let duplicateList = enableList.filter(f => f.displayOrder === displayOrder);
        if (!isEmpty(duplicateList)) {
          duplicateNumberList = [...duplicateNumberList, ...duplicateList];
        }
      });
    }
    if (duplicateNumberList && duplicateNumberList.length > 0) {
      setAlertDescription('Please check the policies before posting.');
      setDisplayWarningList(['The sort number for the [' + enableTarget.policyComment + '] option in the [' + enableTarget.countries.toString() + '] country is duplicated.']);
      setIsAlert(true);
    } else {
      enablePatchTermsPolicy(policyIdx, event.target.checked ? 'enable' : 'disable', getTermsPolicyList);
    }
  };

  const publishHandler = () => {
    setIsPublish(true);
  };

  const isEmpty = input => {
    if (typeof input === 'undefined' || input === null || input === 'null' || input === '' || input.length === 0 || (typeof input === 'string' && input.trim().length === 0) || (Array.isArray(input) && input.length === 0) || (typeof input === 'object' && !Object.keys(input).length)) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel>Device Type</InputLabel>
          <Select value={listFilter.deviceType} name="deviceType" onChange={e => listFilterHandler(e)}>
            {['All', ...deviceTypes].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select value={listFilter.country} name="country" onChange={e => listFilterHandler(e)}>
            {['All', ...countries].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="terms_area tbl_wrap">
        <div className={'btn_set_wrap tbl_top1'}>
          <Button
            color="secondary"
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setTermsPolicy({ ...defaultTermsPolicy });
              setPopupType({ ...defaultPopupType, add: true });
              setOpenForm(true);
            }}>
            Add
          </Button>
          <Button color="secondary" variant="contained" className={'btn_color2'} onClick={publishHandler} disabled={publishButtonDisabled}>
            Publish
          </Button>
        </div>
        <MaterialTable
          title=""
          columns={[
            { title: 'Device', field: 'deviceType' },
            { title: 'Terms Type', render: rowData => (rowData.policyType === 'terms' ? 'Terms of Use' : 'Privacy Policy') },
            { title: 'Country', field: 'countries' },
            { title: 'Platform', field: 'devicePlatforms' },
            { title: 'Display', field: 'displayOption' },
            {
              title: 'Version',
              render: rowData => (
                <button
                  onClick={() => {
                    getTermsPolicy(rowData.idx, viewTermsPolicy);
                  }}>
                  <span className="link">{rowData.policyVersion}</span>
                </button>
              ),
            },
            { title: 'Enable', field: 'enableFlag', render: rowData => <Switch checked={rowData.enableFlag === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} onClick={event => enableHandler(event, rowData.idx)} /> },
            { title: 'published', field: 'publishedFlag', render: rowData => (rowData.publishedVersion ? rowData.publishedFlag + ' (Released: ' + rowData.publishedVersion + ')' : rowData.publishedFlag) },
            { title: 'Comment', field: 'policyComment' },
            { title: 'Change Date', field: 'crtDate' },
            { title: 'Change ID', field: 'crtUsrId' },
            {
              title: 'Action',
              field: 'Action',
              width: '10%',
              render: rowData => (
                <div className={'btn_set_wrap_tbl'}>
                  <Button
                    variant="contained"
                    className={'btn_color3 btn_size_tbl'}
                    disabled={rowData.deviceType === 'mobile'}
                    onClick={() => {
                      if (rowData.deviceType !== 'mobile') {
                        getTermsPolicy(rowData.idx, editTermsPolicy);
                      }
                    }}>
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const term = { ...rowData };
                      setTermsPolicy(term);
                      setPopupType({ ...defaultPopupType, history: true });
                      setOpenHistory(true);
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    History
                  </Button>
                </div>
              ),
            },
          ]}
          data={displayTermsPolicyList}
          options={{
            paging: false,
            search: true,
            rowStyle: rowData => ({
              backgroundColor: rowData.publishedFlag === 'Y' ? '#e0e0e0' : '#FFF',
            }),
          }}
        />
      </div>
      {popupType.add && (
        <Dialog open={openForm} className={'pop_wrap'}>
          <TermsPolicyMultipleForm editPolicyType={'terms'} title="Terms Of Use" closeHandler={() => setOpenForm(false)} getTermsPolicyList={termsPolicySaveSuccess} supportLanguages={supportLanguages} ricCountry={ricCountry} regionCountryByDevice={regionCountryByDevice} />
        </Dialog>
      )}
      {popupType.edit && (
        <Dialog open={openForm} className={'pop_wrap'}>
          <TermPolicyForm edit={popupType.edit} detail={termsPolicy} title="Terms Of Use" closeHandler={() => setOpenForm(false)} getTermsPolicyList={termsPolicySaveSuccess} supportLanguages={supportLanguages} ricCountry={ricCountry} regionCountryByDevice={regionCountryByDevice} />
        </Dialog>
      )}
      {popupType.preview && (
        <Dialog open={openView} className={'pop_wrap'}>
          <TermsPolicyView termsPolicy={termsPolicy} title="Terms Of Use" closeHandler={() => setOpenView(false)} />
        </Dialog>
      )}
      {popupType.history && (
        <Dialog open={openHistory} className={'pop_wrap'}>
          <TermsPolicyHistory title="Terms Of Use" closeHandler={() => setOpenHistory(false)} policyId={termsPolicy.policyId} getTermsPolicy={getTermsPolicy} />
        </Dialog>
      )}
      <ConfirmDialog
        openDialog={isPublish}
        description={'Do you want to publish?'}
        confirmHandler={() => {
          publishTermsPolicy(getTermsPolicyList);
        }}
        closeHandler={() => {
          setIsPublish(false);
        }}
      />
      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => {
          setIsAlert(false);
        }}
      />
    </Fragment>
  );
}

TermsOfUse.propTypes = {};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TermsOfUse));
