import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Dialog, Switch } from '@material-ui/core';
import TermPolicyForm from './TermPolicyForm';
import { getPrivacyPoliciesAction, addPrivacyPolicyAction, updatePrivacyPolicyAction, publishTermsAction, getSupportLanguagesAction, policyPublishAction } from '../../actions/webActions';
import TermPolicyView from './TermPolicyView';
import TermPolicyHistory from './TermPolicyHistory';

function PrivacyPolicy({ getPrivacyPolicies, privacyPolicies, addPrivacyPolicy, updatePrivacyPolicy, publishPrivacyPolicy, getSupportLanguages, policyPublish }) {
  useEffect(() => {
    getPrivacyPolicies();
    getSupportLanguages();
  }, []);

  const [open, setOpen] = React.useState(false);
  const defaultPopupType = {
    add: false,
    edit: false,
    preview: false,
    history: false,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);
  const defaultData = {
    type: 'privacyPolicy',
    comment: '',
    version: '',
    countryCode: [],
    lang: [],
    termMultiLangList: [],
    enable: 'N',
    typeOrder: '',
    mandatory: '',
    deviceType: '',
    devicePlatform: '',
    deviceVersion: '',
  };
  const [privacypolicy, setPrivacypolicy] = useState();

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType' ) {
      setPrivacypolicy({ ...privacypolicy, ...{ [name]: value, countryCode: [], typeOrder: '',  mandatory: '', devicePlatform: '' } });
    } else if (name === 'enable' || name === 'updateVersion') {
      setPrivacypolicy({ ...privacypolicy, ...{ [name]: e.target.checked ? 'Y' : 'N' } });
    } else {
      setPrivacypolicy({ ...privacypolicy, ...{ [name]: value } });
    }
  };

  const handleInputNumberChange = e => {
    const { name, value } = e.target;
    const onlyNumber = value.replace(/[^-/.0-9]/g, '');
    setPrivacypolicy({ ...privacypolicy, ...{ [name]: onlyNumber } });
  };

  const handleLangConentsChange = langData => {
    setPrivacypolicy({ ...privacypolicy, ...{ termMultiLangList: langData } });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // console.log('privacypolicy', privacypolicy);
    if (popupType.edit) {
      updatePrivacyPolicy(privacypolicy);
    } else {
      addPrivacyPolicy(privacypolicy);
    }

    setOpen(false);
  };

  const handlePublish = e => {
    e.preventDefault();
    publishPrivacyPolicy(defaultData.type);
  };

  const getPrivacyPolicyData = rowData => {
    setOpen(true);
    setPrivacypolicy(rowData);
  };

  const handleIndividualPublish = id => {
    policyPublish(id);
  };
  return (
    <Fragment>
      <div className="terms_area tbl_wrap">
        <div className={'btn_set_wrap tbl_top1'}>
          <Button
            color="secondary"
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              // ==== add Popup
              setOpen(true);
              setPopupType({ ...defaultPopupType, add: true });
              setPrivacypolicy(defaultData);
            }}>
            Add
          </Button>
          <Button color="secondary" variant="contained" className={'btn_color2'} onClick={handlePublish}>
            Publish
          </Button>
        </div>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'deviceType',
              field: 'deviceType',
              render: rowData => (rowData.deviceType),
            },
            {
              title: 'country',
              field: 'countryCode',
              render: rowData => (rowData.newCountryCode ? rowData.newCountryCode : rowData.countryCode),
            },
            {
              title: 'version',
              render: rowData => (
                <button
                  onClick={() => {
                    getPrivacyPolicyData(rowData);
                    setPopupType({ ...defaultPopupType, preview: true });
                  }}>
                  <span className="link">{rowData.version}</span>
                </button>
              ),
            },
            {
              title: 'enable',
              field: 'enable',
              render: rowData => <Switch checked={rowData.enable === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} />,
            },
            {
              title: 'comment',
              field: 'comment',
            },
            {
              title: 'lastChgDate',
              field: 'lastChgDate',
            },
            {
              title: 'action',
              field: 'Action',
              width: '10%',
              render: rowData => (
                <div className={'btn_set_wrap_tbl'}>
                  <Button
                    variant="contained"
                    disabled={rowData.deviceType === 'pc'}
                    onClick={() => {
                      if(rowData.deviceType !== 'pc'){
                        setOpen(true);
                        setPopupType({ ...defaultPopupType, edit: true });
                        // adding languges
                        const policy = { ...rowData };
                        policy['countryCode'] = policy.countryCode.split(',');
                        policy['newCountryCode'] = policy.newCountryCode ? policy.newCountryCode.split(',') : policy.countryCode;
                        policy['updateVersion'] = '';
                        policy['lang'] = policy.termMultiLangList ? policy.termMultiLangList.map(x => x.langCode) : [];
                        setPrivacypolicy(policy);
                      }
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                      setPopupType({ ...defaultPopupType, history: true });
                      // adding languges
                      const policy = { ...rowData };
                      policy['countryCode'] = policy.countryCode.split(',');
                      policy['lang'] = policy.termMultiLangList ? policy.termMultiLangList.map(x => x.langCode) : [];
                      setPrivacypolicy(policy);
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    History
                  </Button>
                  {(rowData.enable  === 'Y' ) && (
                  <Button variant="contained" className={'btn_color2 btn_size_tbl'} onClick={() => handleIndividualPublish(rowData.idx)}>
                    Publish
                  </Button>
                  )}
                </div>
              ),
            },
          ]}
          data={privacyPolicies}
          options={{
            paging: false,
            search: true,
            rowStyle: { backgroundColor: '#fff' },
          }}
        />
      </div>
      <Dialog open={open} className={'pop_wrap'}>
        {popupType.preview && <TermPolicyView type={'privacyPolicy'} data={privacypolicy} title="Privacy Policy" handleClose={() => setOpen(false)} />}
        {(popupType.add || (popupType.edit)) && <TermPolicyForm edit={popupType.edit} data={privacypolicy} title="Privacy Policy" handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} handleLangConentsChange={handleLangConentsChange} handleInputNumberChange={handleInputNumberChange}/>}
        {popupType.history && <TermPolicyHistory type={'privacyPolicy'} title="Privacy Policy" handleClose={() => setOpen(false)} historyId={privacypolicy.historyId} />}
      </Dialog>
    </Fragment>
  );
}
PrivacyPolicy.propTypes = {
  privacyPolicies: PropTypes.array.isRequired,
  getPrivacyPolicies: PropTypes.func.isRequired,
  addPrivacyPolicy: PropTypes.func.isRequired,
  updatePrivacyPolicy: PropTypes.func.isRequired,
  publishPrivacyPolicy: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  policyPublish: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  privacyPolicies: state.web.privacypolicies,
});

const mapDispatchToProps = dispatch => ({
  getPrivacyPolicies: () => dispatch(getPrivacyPoliciesAction()),
  addPrivacyPolicy: data => dispatch(addPrivacyPolicyAction(data)),
  updatePrivacyPolicy: data => dispatch(updatePrivacyPolicyAction(data)),
  publishPrivacyPolicy: data => dispatch(publishTermsAction(data)),
  getSupportLanguages: () => dispatch(getSupportLanguagesAction()),
  policyPublish: id => dispatch(policyPublishAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivacyPolicy));
