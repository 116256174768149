import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { Button, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableCell, TableContainer, TableRow, TextField, Checkbox } from '@material-ui/core';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import AlertDialog from '../../../common/dialog/AlertDialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PolicyContent from '../PolicyContent';

function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
}

function TermsPolicyMultipleForm({ editPolicyType, title, closeHandler, getTermsPolicyList, supportLanguages, ricCountry, regionCountryByDevice }) {
  const compareVersions = require('compare-versions');
  const defaultTermsPolicy = {
    idx: -1,
    policyId: '',
    policyType: '',
    policyVersion: '0.0.0',
    definedVersion: '0.0.0',
    policyMajorVersion: 0,
    policyMinorVersion: 0,
    policyPatchVersion: 0,
    deviceType: '',
    policyComment: '',
    countryCode: '',
    ricCode: '',
    contentLanguageCodeList: [],
    devicePlatformCodeList: [],
    contentList: [],
    multipleDisplay: [],
  };
  const defaultOption = {
    displayTempId: '',
    displayOption: '',
    displayOrder: '',
  };
  const defaultContent = {
    displayTempId: '',
    contentIdx: null,
    languageCode: '',
    policyTitle: '',
    policyDescription: '',
  };
  const devicePlatform = [
    { type: 'tv', value: 'webOS' },
    { type: 'auto', value: 'webOS' },
    { type: 'mobile', value: 'Android' },
    { type: 'mobile', value: 'IOS' },
    { type: 'pc', value: 'pc_platform' },
  ];
  const displayOptionList = ['mandatory', 'optional', 'notice'];
  const [displayTempOption, setDisplayTempOption] = useState('');
  const [displayTempOrder, setDisplayTempOrder] = useState('');
  const [displayTempIdCount, setDisplayTempIdCount] = useState(1);
  const [confirmDescription, setConfirmDescription] = useState('Would you like to register?');
  const [validDescription, setValidDescription] = useState('Would you like to register?');
  const [isSave, setIsSave] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [termsPolicy, setTermsPolicy] = useState(defaultTermsPolicy);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [countryDropDownList, setCountryDropDownList] = useState([]);
  const [languageDropDownList, setLanguageDropDownList] = useState([]);
  const [appliedOptionId, setAppliedOptionId] = useState('');
  const [isAddOptionText, setIsAddOptionText] = useState(false);
  const [multipleLanguageList, setMultipleLanguageList] = useState([]);

  useEffect(() => {
    if (editPolicyType) {
      setTermsPolicy({ ...termsPolicy, ...{ policyType: editPolicyType } });
    }
  }, [editPolicyType]);

  useEffect(() => {
    if (regionCountryByDevice && regionCountryByDevice.length > 0) {
      setDeviceTypeList(regionCountryByDevice.map(device => device.deviceType));
    }
  }, [regionCountryByDevice]);

  useEffect(() => {
    if (termsPolicy.deviceType && regionCountryByDevice && supportLanguages && regionCountryByDevice.length > 0 && supportLanguages.languageByCountry.length > 0) {
      let country = regionCountryByDevice
        .find(device => termsPolicy.deviceType === device.deviceType)
        .regionList.map(country => country.countryList)
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
        .map(m => m.countryCode);
      country = [...new Set(country)];
      let deviceCountry = supportLanguages.languageByCountry.filter(f => country.includes(f.countryCode));
      setCountryDropDownList(deviceCountry);
    }
  }, [termsPolicy.deviceType]);

  useEffect(() => {
    if (supportLanguages && supportLanguages.distinctLanguage.length > 0) {
      setLanguageDropDownList(supportLanguages.distinctLanguage.map(m => m.languageCode));
    }
  }, [supportLanguages]);

  const termsPolicyInputChangeHandler = e => {
    const { name, value } = e.target;
    if (name === 'deviceType') {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value, devicePlatformCodeList: [], countryCode: '', ricCode: '' } });
    } else if (name === 'policyComment') {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value } });
    } else if (name === 'displayOrder') {
      if (isEmpty(value)) {
        setTermsPolicy({ ...termsPolicy, ...{ [name]: '' } });
      } else {
        if (isFinite(value)) {
          setTermsPolicy({ ...termsPolicy, ...{ [name]: Number(value) < 1 ? 1 : value } });
        }
      }
    } else if (name === 'countryCode') {
      let ric = '';
      if (!isEmpty(value)) {
        ric = ricCountry.find(f => f.country_code === value).ric_code;
      }
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value, ricCode: ric } });
    } else if (name === 'devicePlatformCodeList') {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value } });
    } else {
      setTermsPolicy({ ...termsPolicy, ...{ [name]: value } });
    }
  };

  const confirmHandler = e => {
    e.preventDefault();
    // Policy Type
    if (isEmpty(termsPolicy.policyType)) {
      setValidDescription('No policy type has been selected.');
      setIsAlert(true);
      return;
    }
    // Device Type
    if (isEmpty(termsPolicy.deviceType)) {
      setValidDescription('No device type has been selected.');
      setIsAlert(true);
      return;
    }
    // Device Platform
    if (isEmpty(termsPolicy.devicePlatformCodeList)) {
      setValidDescription('No device Platform has been selected.');
      setIsAlert(true);
      return;
    }
    // country
    if (isEmpty(termsPolicy.countryCode)) {
      setValidDescription('Select the country this policy applies to.');
      setIsAlert(true);
      return;
    }
    // Policy Comment
    // version
    if (isEmpty(termsPolicy.policyVersion) || !compareVersions.validate(termsPolicy.policyVersion)) {
      setValidDescription('The version format to apply was entered incorrectly.');
      setIsAlert(true);
      return;
    }
    if (!compareVersions.compare(termsPolicy.definedVersion, termsPolicy.policyVersion, '<')) {
      setValidDescription('It should be upgraded from the previous version.');
      setIsAlert(true);
      return;
    }
    //multipleDisplay
    if (isEmpty(termsPolicy.multipleDisplay)) {
      setValidDescription('Policy options and sequence have not been determined.');
      setIsAlert(true);
      return;
    } else {
      let unwritten = termsPolicy.multipleDisplay.find(f => isEmpty(f.displayOrder));
      if (unwritten) {
        setValidDescription('[' + unwritten.displayOption + '] sort number for the option is not recorded.');
        setIsAlert(true);
        return;
      }
      let arrOrder = termsPolicy.multipleDisplay.map(m => m.displayOrder);
      let orderValid = arrOrder.some(function (x) {
        return arrOrder.indexOf(x) !== arrOrder.lastIndexOf(x);
      });
      if (orderValid) {
        setValidDescription('Duplicate policy sort number.');
        setIsAlert(true);
        return;
      }
    }
    // title
    // description
    let isValid = true;
    let msg = '';
    termsPolicy.multipleDisplay.forEach(fo => {
      let option = termsPolicy.contentList.filter(f => fo.displayTempId === f.displayTempId);
      if (isEmpty(option)) {
        setValidDescription('No policy has been entered.[' + fo.displayOption + ']');
        isValid = false;
        return;
      }
      option.forEach(op => {
        if (isEmpty(op.policyTitle)) {
          isValid = false;
          setValidDescription('Please enter a title for your [' + fo.displayOption + '][' + op.languageCode + '] policy.');
          return;
        } else if (isEmpty(op.policyDescription)) {
          isValid = false;
          setValidDescription('Please enter description for your [' + fo.displayOption + '][' + op.languageCode + '] policy.');
          return;
        }
      });
    });

    if (!isValid) {
      setIsAlert(true);
      return;
    }
    let policyVersion = '';
    policyVersion = ''.concat(termsPolicy.policyMajorVersion, '.', termsPolicy.policyMinorVersion, '.', termsPolicy.policyPatchVersion);
    setTermsPolicy({ ...termsPolicy, ...{ policyVersion: policyVersion } });
    setIsSave(true);
  };

  const isEmpty = input => {
    if (typeof input === 'undefined' || input === null || input === 'null' || input === '' || input.length === 0 || (typeof input === 'string' && input.trim().length === 0) || (Array.isArray(input) && input.length === 0) || (typeof input === 'object' && !Object.keys(input).length)) {
      return true;
    }
    return false;
  };

  const isValidVersion = (previousVersion, inputVersion) => {
    if (isEmpty(previousVersion) || isEmpty(inputVersion) || !compareVersions.validate(previousVersion) || !compareVersions.validate(inputVersion) || !compareVersions.compare(previousVersion, inputVersion, '<=')) {
      return false;
    }
    return true;
  };

  const versionInputHandler = event => {
    event.preventDefault();
    let { name, value } = event.target;
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputVersion = isEmpty(value) ? '' : Number(value);
    if (name === 'policyMajorVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyMajorVersion: inputVersion,
          policyVersion: ''.concat(inputVersion, '.', termsPolicy.policyMinorVersion, '.', termsPolicy.policyPatchVersion),
        },
      });
    } else if (name === 'policyMinorVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyMinorVersion: inputVersion,
          policyVersion: ''.concat(termsPolicy.policyMajorVersion, '.', inputVersion, '.', termsPolicy.policyPatchVersion),
        },
      });
    } else if (name === 'policyPatchVersion') {
      setTermsPolicy({
        ...termsPolicy,
        ...{
          policyPatchVersion: inputVersion,
          policyVersion: ''.concat(termsPolicy.policyMajorVersion, '.', termsPolicy.policyMinorVersion, '.', inputVersion),
        },
      });
    }
  };

  const saveTermsPolicy = () => {
    let body = { ...termsPolicy };
    fetch(`${API_BASE_URL}/admin/terms-policy`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (getTermsPolicyList) {
            getTermsPolicyList('Successfully save !');
          }
        } else {
          alert('[server error] ' + body.message);
        }
      })
      .catch(error => {
        console.log('saveTermsPolicy error: ', error);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  const numberChangeHandler = (value, setValue) => {
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputValue = isEmpty(value) ? '' : Number(value);
    setValue(inputValue);
    let displayList = [...termsPolicy.multipleDisplay];
    if (isFinite(inputValue) && displayList && displayList.length > 0 && displayList.map(m => m.displayOrder).findIndex(f => inputValue === f) > -1) {
      setIsAddOptionText(true);
    } else {
      setIsAddOptionText(false);
    }
  };

  const addOptionHandler = event => {
    event.preventDefault();
    let displayList = [...termsPolicy.multipleDisplay];
    if (displayList && displayList.length > 0) {
      if (displayList.map(m => m.displayOrder).findIndex(f => displayTempOrder === f) > -1) {
        setIsAddOptionText(true);
        return;
      }
    }
    let option = { ...defaultOption };
    option.displayTempId = displayTempOption.concat('_', displayTempIdCount);
    option.displayOption = displayTempOption;
    option.displayOrder = displayTempOrder;
    displayList.push(option);
    setTermsPolicy({ ...termsPolicy, ...{ multipleDisplay: displayList } });
    setDisplayTempOption('');
    setDisplayTempOrder('');
    setDisplayTempIdCount(displayTempIdCount + 1);
    setIsAddOptionText(false);
    if (displayList && displayList.length === 1) {
      setAppliedOptionId(option.displayTempId);
    }
  };

  const removeOptionHandler = (event, displayTempId) => {
    event.preventDefault();
    let excludedList = [...termsPolicy.multipleDisplay].filter(f => f.displayTempId !== displayTempId);
    setTermsPolicy({ ...termsPolicy, ...{ multipleDisplay: excludedList } });
  };

  const appliedContentListHandler = (event, tempId) => {
    event.preventDefault();
    setAppliedOptionId(tempId);
    let appliedContentList = [...termsPolicy.contentList].filter(f => f.displayTempId === tempId);
    setMultipleLanguageList(appliedContentList.map(f => f.languageCode));
  };

  const orderValidityCheck = display => {
    let applyList = [...termsPolicy.multipleDisplay].filter(f => f.displayTempId !== display.displayTempId);
    if (applyList && applyList.length > 0 && applyList.map(m => m.displayOrder).findIndex(f => display.displayOrder === f) > -1) {
      return true;
    }
    return false;
  };

  const orderChangeHandler = (value, displayId) => {
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputValue = isEmpty(value) ? '' : Number(value);
    let displayList = [...termsPolicy.multipleDisplay];
    displayList.forEach(f => {
      if (f.displayTempId === displayId) {
        f.displayOrder = inputValue;
      }
    });
    setTermsPolicy({ ...termsPolicy, ...{ multipleDisplay: displayList } });
  };

  const contentChangeHandler = contentData => {
    let multipleContentList = [...termsPolicy.contentList];
    let excludedContentList = multipleContentList.filter(f => f.displayTempId !== appliedOptionId);
    setTermsPolicy({ ...termsPolicy, ...{ contentList: [...excludedContentList, ...contentData] } });
  };

  const multipleDisplayLanguageChangeHandler = (language, displayId) => {
    if (!isEmpty(displayId)) {
      setMultipleLanguageList(language);
      let multipleContentList = [...termsPolicy.contentList];
      let focusContentList = multipleContentList.filter(f => f.displayTempId === displayId);
      let excludedContentList = multipleContentList.filter(f => f.displayTempId !== displayId);
      if (isEmpty(language)) {
        setMultipleLanguageList([]);
        setTermsPolicy({ ...termsPolicy, ...{ contentList: excludedContentList } });
      } else if (focusContentList.length > language.length) {
        let removeContentList = focusContentList.filter(f => language.includes(f.languageCode));
        setTermsPolicy({ ...termsPolicy, ...{ contentList: [...excludedContentList, ...removeContentList] } });
      } else if (focusContentList.length < language.length) {
        let newLanguage = language.find(f => !focusContentList.map(m => m.languageCode).includes(f));
        let addContent = { ...defaultContent };
        addContent.displayTempId = displayId;
        addContent.languageCode = newLanguage.toString();
        focusContentList.push(addContent);
        setTermsPolicy({ ...termsPolicy, ...{ contentList: [...excludedContentList, ...focusContentList] } });
      } else {
        setMultipleLanguageList(language);
        setTermsPolicy({ ...termsPolicy, ...{ contentList: multipleContentList } });
      }
    }
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {'Add'} {title}
        <IconButton aria-label="close" onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form>
        <DialogContent className={'pop_body'}>
          <div>
            {termsPolicy && (
              <TableContainer className={'tbl_wrap'}>
                <Table className={'tbl_row_typ1'}>
                  <tbody>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell width="15%">
                        <label className="is_required">Device Type</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="deviceType" id="deviceType" required fullWidth value={termsPolicy.deviceType ? termsPolicy.deviceType : ''} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {deviceTypeList
                            .filter(f => f !== 'mobile')
                            .map(item => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                      <TableCell width="15%">
                        <label className="is_required">Platform</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select name="devicePlatformCodeList" id="devicePlatformCodeList" required fullWidth multiple value={termsPolicy.devicePlatformCodeList ? termsPolicy.devicePlatformCodeList : []} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {devicePlatform
                            .filter(f => f.type === termsPolicy.deviceType)
                            .map(item => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell width="15%">
                        <label className="is_required">country</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <Select name="countryCode" id="countryCode" required fullWidth value={termsPolicy.countryCode} onChange={event => termsPolicyInputChangeHandler(event)}>
                          {countryDropDownList.map(item => (
                            <MenuItem key={item.countryCode} value={item.countryCode}>
                              {item.countryCode + '(' + item.defaultLanguage + ')'}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="">comment</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <label className="">Automatically generated based on ‘Device Type’, ‘Option Type’, and ‘Sort Order’.</label>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="is_required">version</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <TextField name="policyMajorVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyMajorVersion} onChange={event => versionInputHandler(event)} />
                        <span style={{ display: 'inline-block' }}>
                          <h4 style={{ margin: '8px' }}>.</h4>
                        </span>
                        <TextField name="policyMinorVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyMinorVersion} onChange={event => versionInputHandler(event)} />
                        <span style={{ display: 'inline-block' }}>
                          <h4 style={{ margin: '8px' }}>.</h4>
                        </span>
                        <TextField name="policyPatchVersion" required style={{ width: 78 }} inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={termsPolicy.policyPatchVersion} onChange={event => versionInputHandler(event)} />
                      </TableCell>
                      <TableCell>
                        <label className="is_required">Update version</label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <span style={{ color: isValidVersion(termsPolicy.definedVersion, termsPolicy.policyVersion, termsPolicy.upgradedVersionFlag) ? '#000' : 'red' }}>
                          <h5>{termsPolicy.policyVersion}</h5>
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell>
                        <label className="is_required">Display Option</label>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Select name="displayTempOption" id="displayOption" required fullWidth value={displayTempOption} onChange={event => setDisplayTempOption(event.target.value)}>
                          {displayOptionList.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <label className="is_required">Display Order</label>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <TextField name="displayTempOrder" required fullWidth inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={displayTempOrder} onChange={event => numberChangeHandler(event.target.value, setDisplayTempOrder)} />
                      </TableCell>
                      <TableCell>
                        {isAddOptionText && (
                          <label className="" style={{ color: 'red' }}>
                            <span>duplicate order!</span>
                          </label>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className={'btn_set_wrap'}>
                          <Button type="button" color="primary" className={'btn_color2'} style={{ marginTop: '-15px' }} disabled={displayTempOption && displayTempOrder && displayTempOrder > 0 && !isAddOptionText ? false : true} onClick={addOptionHandler}>
                            Add option
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                    {termsPolicy.multipleDisplay && termsPolicy.multipleDisplay.length > 0 && (
                      <Fragment>
                        {termsPolicy.multipleDisplay.map(display => (
                          <TableRow width="100%" style={{ height: '80px' }} key={'TableRow_' + display.displayTempId}>
                            <TableCell colSpan={3}>
                              <AppBar position="static" id="scheduleTabHead">
                                <Tabs value={appliedOptionId === display.displayTempId ? display.displayTempId : false} onChange={event => appliedContentListHandler(event, display.displayTempId)}>
                                  <Tab key={display.displayTempId} value={display.displayTempId} label={display.displayOption} {...a11yProps(display.displayTempId)} />
                                </Tabs>
                              </AppBar>
                            </TableCell>
                            <TableCell width="15%">
                              <TextField name="displayTempOrder" required fullWidth inputProps={{ maxLength: 3 }} margin="dense" variant="outlined" value={display.displayOrder} onChange={event => orderChangeHandler(event.target.value, display.displayTempId)} />
                            </TableCell>
                            <TableCell>
                              {orderValidityCheck(display) && (
                                <label className="" style={{ color: 'red' }}>
                                  <span>duplicate order!</span>
                                </label>
                              )}
                            </TableCell>
                            <TableCell colSpan={1}>
                              <div className={'btn_set_wrap'}>
                                <Button type="button" color="primary" className={'btn_color2'} style={{ marginTop: '-15px' }} onClick={event => removeOptionHandler(event, display.displayTempId)}>
                                  Delete option
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Fragment>
                    )}
                    <TableRow width="100%" style={{ height: '80px' }}>
                      <TableCell width="15%">
                        <label className="is_required">language</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <Select name="contentLanguageCodeList" id="contentLanguageCodeList" required fullWidth multiple value={multipleLanguageList} onChange={event => multipleDisplayLanguageChangeHandler(event.target.value, appliedOptionId)}>
                          {languageDropDownList.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow width="100%">
                      <TableCell width="10%">
                        <label className="is_required">Content</label>
                      </TableCell>
                      <TableCell width="90%" colSpan={5}>
                        {!isEmpty(appliedOptionId) && (
                          <Fragment>
                            {termsPolicy.multipleDisplay.map(display => (
                              <div style={{ border: '0px' }} hidden={!display.displayTempId === appliedOptionId}>
                                {display.displayTempId === appliedOptionId && (
                                  <PolicyContent
                                    contentData={termsPolicy.contentList && termsPolicy.contentList.map(m => m.displayTempId === display.displayTempId).length > 0 ? termsPolicy.contentList.filter(m => m.displayTempId === appliedOptionId) : []}
                                    contentChangeHandler={contentChangeHandler}
                                  />
                                )}
                              </div>
                            ))}
                          </Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={closeHandler} className={'btn_color1'} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="button" color="primary" onClick={confirmHandler} className={'btn_color2'} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <ConfirmDialog
        openDialog={isSave}
        description={confirmDescription}
        confirmHandler={() => {
          saveTermsPolicy();
        }}
        closeHandler={() => {
          setIsSave(false);
        }}
      />
      <AlertDialog
        openAlert={isAlert}
        description={validDescription}
        warningList={[]}
        closeHandler={() => {
          setIsAlert(false);
        }}
      />
    </Fragment>
  );
}

TermsPolicyMultipleForm.propTypes = {
  policyType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  getTermsPolicyList: PropTypes.func,
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps)(TermsPolicyMultipleForm);
